import { Languages } from "src/common/descriptions/types";
import { CreateSupplierDescriptions } from "./types";

const DescriptionsEnUs: CreateSupplierDescriptions = {
    TITLE: "Create Supplier",
    SAVE_BUTTON: "Save",
    CANCEL_BUTTON: "Cancel",
    CONFIRM_MODAL: {
        TITLE: 'Save supplier?',
        DESCRIPTION: "Operation will not be reverted.",
        ACTION_BUTTON: "Save",
        CANCEL_BUTTON: "Go Back",
    },
    CANCEL_MODAL: {
        TITLE: "Cancel Supplier input?",
        DESCRIPTION: "All data will be lost.",
        ACTION_BUTTON: "Yes, Cancel",
        CANCEL_BUTTON: "Keep working",
    },
    SUCCESS_MODAL: {
        TITLE: "Success",
        PRIMARY_LABEL: "Accept",
        SECONDARY_LABEL: "Accept",
        DESCRIPTION: "Supplier Created",
    },
    SUCCESS_SECONDARY_MODAL: {
        TITLE: "Success",
        PRIMARY_LABEL: "Accept",
        SECONDARY_LABEL: "Accept",
        DESCRIPTION: "Supplier Updated",
    },
    ERRORS: {
        REQUIRED_FIELD: (fieldName: string) => `${fieldName} is required.`,
        SAVE_ACTION: "",
        UNKNOWN: "An error ocurred while processing request, please contact support."
    },
    FORM: {
        NAME: {
            LABEL: "Name",
            PLACEHOLDER: "Input name",
        },
        ADDRESS: {
            LABEL: "Address",
            PLACEHOLDER: "Input addres",
        },
        NIT: {
            LABEL: "NIT",
            PLACEHOLDER: "Input NIT",
        },
        EMAIL: {
            LABEL: "Email",
            PLACEHOLDER: "Input email",
        },
        PHONE: {
            LABEL: "Phone",
            PLACEHOLDER: "Input phone",
        },
        CREDIT_DAYS: {
            LABEL: "Credit days",
            PLACEHOLDER: "Input credit days",
        },
        STATUS: {
            LABEL: "Status",
            PLACEHOLDER: "Select status",
        },
    },
    MAIN_ACTION_TITLE: "Creating Supplier",
    MAIN_ACTION_DESCRIPTION: "Please wait...",
}

const DescriptionsEsUs: CreateSupplierDescriptions = {
    TITLE: "Crear Proveedor",
    SAVE_BUTTON: "Guardar",
    CANCEL_BUTTON: "Cancelar",
    CONFIRM_MODAL: {
        TITLE: 'Guardar Proveedor con los datos ingresados?',
        DESCRIPTION: "La operación no podrá ser revertida.",
        ACTION_BUTTON: "Guardar",
        CANCEL_BUTTON: "Regresar",
    },
    CANCEL_MODAL: {
        TITLE: "Cancelar ingreso de Proveedor?",
        DESCRIPTION: "La información ingresada sera borrada.",
        ACTION_BUTTON: "Si, Cancelar",
        CANCEL_BUTTON: "Seguir ingresando",
    },
    SUCCESS_MODAL: {
        TITLE: "Exito",
        PRIMARY_LABEL: "Aceptar",
        SECONDARY_LABEL: "Aceptar",
        DESCRIPTION: "Proveedor creado",
    },
    SUCCESS_SECONDARY_MODAL: {
        TITLE: "Exito",
        PRIMARY_LABEL: "Aceptar",
        SECONDARY_LABEL: "Aceptar",
        DESCRIPTION: "Proveedor Actualizado",
    },
    ERRORS: {
        REQUIRED_FIELD: (fieldName: string) => `${fieldName} es requerido.`,
        SAVE_ACTION: "",
        UNKNOWN: "Ha ocurrido un error al procesar la operación, por favor contacta a soporte."
    },
    FORM: {
        NAME: {
            LABEL: "Nombre",
            PLACEHOLDER: "Ingrese nombre",
        },
        ADDRESS: {
            LABEL: "Dirección",
            PLACEHOLDER: "Ingrese dirección",
        },
        NIT: {
            LABEL: "NIT",
            PLACEHOLDER: "Ingrese NIT",
        },
        EMAIL: {
            LABEL: "Correo",
            PLACEHOLDER: "Ingrese correo",
        },
        PHONE: {
            LABEL: "Teléfono",
            PLACEHOLDER: "Ingrese teléfono",
        },
        CREDIT_DAYS: {
            LABEL: "Días de crédito",
            PLACEHOLDER: "Ingrese días de crédito",
        },
        STATUS: {
            LABEL: "Estado",
            PLACEHOLDER: "Seleccione estado",
        },
    },
    MAIN_ACTION_TITLE: "Creando Tipo de Pago",
    MAIN_ACTION_DESCRIPTION: "Por favor espere...",
}

export const createSupplierDictionary = {}
createSupplierDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
createSupplierDictionary[Languages.SPANISH_US] = DescriptionsEsUs