import { createStore, applyMiddleware, Store, Middleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'
import { RootState } from 'src/state/reducer'
import rootReducer from 'src/state/reducer'

import securitySagas from 'src/features/Security/state/sagas'
import customerSagas from 'src/features/Customer/state/sagas'
import customerPaymentSagas from 'src/features/CustomerPayment/state/sagas'
import supplierSagas from 'src/features/Supplier/state/sagas'
import userSagas from 'src/features/User/state/sagas'
import productSagas from 'src/features/Product/state/sagas'
import productSettingsSagas from 'src/features/ProductSettings/state/sagas'
import purchaseSagas from 'src/features/Purchase/state/sagas'
import routingSagas from 'src/features/Routing/state/sagas'
import regularizationSagas from 'src/features/StockRegularization/state/sagas'
import saleSagas from 'src/features/Sale/state/sagas'
import reportSagas from 'src/features/Reports/state/sagas'
import dteSagas from 'src/features/Dte/state/sagas'
import commonSagas from 'src/common/state/sagas'

const sagas = createSagaMiddleware()
const middleware: [Middleware] = [sagas]

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    middleware.push(logger);
}

const store: Store<RootState> = createStore(
    rootReducer,
    applyMiddleware(...middleware)
);

sagas.run(securitySagas)
sagas.run(commonSagas)
sagas.run(customerSagas)
sagas.run(customerPaymentSagas)
sagas.run(dteSagas)
sagas.run(productSagas)
sagas.run(productSettingsSagas)
sagas.run(purchaseSagas)
sagas.run(regularizationSagas)
sagas.run(reportSagas)
sagas.run(routingSagas)
sagas.run(saleSagas)
sagas.run(supplierSagas)
sagas.run(userSagas)

export default store;