import { Languages } from "src/common/descriptions/types";
import { SuppliersDescriptions } from "./types";

const DescriptionsEnUs: SuppliersDescriptions = {
    TITLE: "Suppliers",
    TABLE: {
        ACTION_BUTTON: "Create Supplier",
        SEARCH_PLACEHOLDER: "Search Supplier",
        LOADING_DATA: "Loading data...",
    },
    TABLE_COLUMNS: {
        ID: "No",
        NAME: "Name",
        NIT: "NIT",
        PHONE: "Phone",
        ADDRESS: "Address",
        EMAIL: "Email",
        CREDI_DAYS: "Credit Days",
        STATUS: "Status",
        ACTIONS: "Actions",
    },
}

const DescriptionsEsUs: SuppliersDescriptions = {
    TITLE: "Proveedores",
    TABLE: {
        ACTION_BUTTON: "Crear Proveedor",
        SEARCH_PLACEHOLDER: "Buscar Proveedor",
        LOADING_DATA: "Cargando información...",
    },
    TABLE_COLUMNS: {
        ID: "No",
        NAME: "Nombre",
        NIT: "NIT",
        PHONE: "Teléfono",
        ADDRESS: "Dirección",
        EMAIL: "Correo",
        CREDI_DAYS: "Días Crédito",
        STATUS: "Estado",
        ACTIONS: "Acciones",
    },
}

export const suppliersDictionary = {}
suppliersDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
suppliersDictionary[Languages.SPANISH_US] = DescriptionsEsUs