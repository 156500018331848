import { Alert, Button, Form, Row, Space } from "antd"
import { Col16, Col4 } from "src/components/Columns"
import { CurrencyInput } from "src/components/CurrencyInput"
import { SearchableTable } from "src/components/SearchableTable"
import { PresentationsColumnsKeys } from "./types"
import { searchableTableUtils } from "src/components/SearchableTable/utils"
import { useEffect, useState } from "react"
import { CreatePresentation } from "./CreatePresentation"
import { ProductPresentation } from "src/common/models/productPresentation"
import { amountValidator, formatNumberAsCurrency } from "src/common/util"
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { SystemDescriptions } from "src/common/descriptions"
import { Product } from "src/common/models/product"
import { useSelector } from "react-redux"
import { RootState } from "src/state/reducer"

interface ReduxProps {
    currentProduct?: Product
}

interface Props {
    onFinish: (coste: number, presentations: Partial<ProductPresentation>[]) => void
    readOnly?: boolean
}

export const PresentationsForm = (props: Props) => {

    const [createOpen, setCreateOpen] = useState<boolean>(false)
    const [productPresentations, setProductPresentations] = useState<Partial<ProductPresentation>[]>([])
    const [currentPresentation, setCurrentPresentation] = useState<Partial<ProductPresentation> | undefined>()
    const [coste, setCoste] = useState<number>(0)
    const [alertMsg, setAlertMsg] = useState<string>()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        currentProduct: state.product.currentProduct,
    }))

    useEffect(() => {
        if (reduxProps.currentProduct) {
            form.setFieldsValue({
                coste: reduxProps.currentProduct.coste,
            })
            setProductPresentations(reduxProps.currentProduct.presentations)
            setCoste(reduxProps.currentProduct.coste)
        }
    }, [reduxProps.currentProduct])

    const [form] = Form.useForm();

    const descriptions = SystemDescriptions.PAGES.PRODUCT.CREATE_PRODUCT.PRESENTATIONS
    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    const tableColumns: any = [
        {
            title: descriptions.COLUMNS.PRESENTATION,
            dataIndex: PresentationsColumnsKeys.PRESENTATION,
            key: PresentationsColumnsKeys.PRESENTATION,
            align: searchableTableUtils.alignment.centerAlign,
        },
        {
            title: descriptions.COLUMNS.QTY,
            dataIndex: PresentationsColumnsKeys.QTY,
            key: PresentationsColumnsKeys.QTY,
            align: searchableTableUtils.alignment.centerAlign,
        },
        {
            title: descriptions.COLUMNS.UNIT_PRICE,
            dataIndex: PresentationsColumnsKeys.UNIT_PRICE,
            key: PresentationsColumnsKeys.UNIT_PRICE,
            align: searchableTableUtils.alignment.centerAlign,
            render: (value) => formatNumberAsCurrency(value, 4),
        },
        {
            title: descriptions.COLUMNS.SALE_PRICE,
            dataIndex: PresentationsColumnsKeys.SALE_PRICE,
            key: PresentationsColumnsKeys.SALE_PRICE,
            align: searchableTableUtils.alignment.centerAlign,
            render: (value) => formatNumberAsCurrency(value, 4),
        },
        {
            title: descriptions.COLUMNS.MIN_SALE_PRICE,
            dataIndex: PresentationsColumnsKeys.MIN_SALE_PRICE,
            key: PresentationsColumnsKeys.MIN_SALE_PRICE,
            align: searchableTableUtils.alignment.centerAlign,
            render: (value) => formatNumberAsCurrency(value, 4),
        },
        {
            title: descriptions.COLUMNS.BARCODE,
            dataIndex: PresentationsColumnsKeys.BARCODE,
            key: PresentationsColumnsKeys.BARCODE,
            align: searchableTableUtils.alignment.centerAlign,
        },
        {
            title: descriptions.COLUMNS.ACTIONS,
            key: 'action',
            align: searchableTableUtils.alignment.centerAlign,
            render: (_, record: ProductPresentation) => {

                const handleEdit = (ref: string) => {
                    const presentation = productPresentations.find(candidate => candidate.ref === ref)
                    setCurrentPresentation(presentation)
                    setCreateOpen(true)
                }

                const handleRemove = (ref: string) => {
                    const newPresentations: Partial<ProductPresentation>[] = []
                    productPresentations.forEach(presentation => {
                        if (presentation.ref !== ref) {
                            newPresentations.push(presentation)
                        }
                    })
                    setProductPresentations(newPresentations)
                }

                return <Space size="middle">
                    <Button type="primary" ghost shape="circle"
                        icon={<EditOutlined rev={undefined} />}
                        onClick={() => handleEdit(record.ref!)}
                        disabled={props.readOnly}
                    />
                    <Button type="primary" ghost danger shape="circle"
                        icon={<DeleteOutlined rev={undefined} />}
                        onClick={() => handleRemove(record.ref!)}
                        disabled={props.readOnly}
                    />
                </Space>
            },
        },
    ];

    useEffect(() => {
        let arePresentationsValid: boolean = true

        if (productPresentations.length === 0) {
            arePresentationsValid = false
            setAlertMsg(descriptions.ALERTS.NO_PRESENTATIONS)
        } else {
            const minimalUnits = productPresentations.filter(candidate => candidate.qty === 1)

            if (minimalUnits.length === 0) {
                arePresentationsValid = false
                setAlertMsg(descriptions.ALERTS.MINIMAL_UNIT_MISSING)
            } else if (minimalUnits.length > 1) {
                arePresentationsValid = false
                setAlertMsg(descriptions.ALERTS.MINIMAL_UNIT_UNIQUE)
            }
        }

        if (arePresentationsValid) {
            setAlertMsg("")
        }

        props.onFinish(
            coste,
            arePresentationsValid ? productPresentations : [],
        )
    }, [coste, productPresentations])


    const buildRequiredMessageErr = (fieldName: string): string => commonDescriptions.FORM.REQUIRED_FIELD(fieldName)

    const handleCosteChange = (e) => {
        const currencyRegex = /^\d+(\.\d{1,2})?$/;

        if (!currencyRegex.test(e.target.value)) {
            return
        }

        setCoste(parseFloat(e.target.value))
    }

    const renderForm = () => (
        <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            autoComplete={'none'}
            disabled={props.readOnly}
        >
            <Row>
                <Col4>
                    <Form.Item
                        label={descriptions.COSTE.LABEL}
                        name={"coste"}
                        rules={[
                            {
                                required: true,
                                message: buildRequiredMessageErr(descriptions.COSTE.LABEL)
                            },
                            {
                                validator: (_, value) => {
                                    return amountValidator(value)
                                }
                            },
                        ]}
                        validateTrigger="onBlur"
                    >
                        <CurrencyInput
                            placeholder={descriptions.COSTE.PLACEHOLDER}
                            onChange={handleCosteChange}
                        />
                    </Form.Item>
                </Col4>
                <Col16></Col16>
                <Col4>
                    <Form.Item
                        label={" "}
                    >
                        {
                            !props.readOnly &&
                            <Button
                                type="primary"
                                style={{ width: "100%" }}
                                onClick={() => { setCreateOpen(true) }}
                                disabled={coste === 0}
                            >
                                {descriptions.TABLE.ACTION_BUTTON}
                            </Button>
                        }
                    </Form.Item>
                </Col4>
            </Row>

            <SearchableTable
                items={productPresentations.sort((a: any, b: any) => a.qty - b.qty)}
                tableColumns={tableColumns}
                showLoader={false}
            />
        </Form>
    )

    const handleAcceptCreatePresentation = (productPresentation: Partial<ProductPresentation>) => {

        if (!currentPresentation) {
            setProductPresentations([
                ...productPresentations,
                productPresentation,
            ])
        } else {
            const newPresentations = productPresentations.map(presentation => {
                if (presentation.ref === productPresentation.ref) {
                    presentation = { ...productPresentation }
                }
                return presentation
            })
            setCurrentPresentation(undefined)
            setProductPresentations(newPresentations)
        }

        setCreateOpen(false)
    }

    const renderCreate = () => (
        <CreatePresentation
            open={createOpen}
            onAccept={handleAcceptCreatePresentation}
            onCancel={() => setCreateOpen(false)}
            initialValues={currentPresentation}
        />
    )

    const renderAlerts = () => {
        return alertMsg && alertMsg?.length > 0 && <Alert message={alertMsg} type="warning" />
    }

    return (
        <>
            {renderAlerts()}
            {renderForm()}
            {renderCreate()}
        </>
    )
}
