import { ApiActionTypes, buildApiTypeAction } from "src/common/apiLoader/apiLoaderUtils";
import { SystemConstants } from "src/common/constants";

const GET_REGULARIZATION_RECORDS = 'GET_REGULARIZATION_RECORDS';
export const GET_REGULARIZATION_RECORDS_API = GET_REGULARIZATION_RECORDS + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_REGULARIZATION_RECORDS_API_SUCCESS = GET_REGULARIZATION_RECORDS + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_REGULARIZATION_RECORDS_API_ERROR = GET_REGULARIZATION_RECORDS + SystemConstants.API_LOADER.ERROR_SUFFIX;

const CREATE_REGULARIZATION = 'CREATE_REGULARIZATION';
export const CREATE_REGULARIZATION_API = buildApiTypeAction(CREATE_REGULARIZATION, ApiActionTypes.REQUEST)
export const CREATE_REGULARIZATION_API_SUCCESS = buildApiTypeAction(CREATE_REGULARIZATION, ApiActionTypes.SUCCESS)
export const CREATE_REGULARIZATION_API_ERROR = buildApiTypeAction(CREATE_REGULARIZATION, ApiActionTypes.ERROR)