import { SystemDescriptions } from "src/common/descriptions";
import { searchableTableUtils } from "src/components/SearchableTable/utils";

const descriptions = SystemDescriptions.PAGES.SUPPLIER.SUPPLIERS
const commonDescriptions = SystemDescriptions.PAGES.COMMON

export enum ColumnsKeys {
    NO = 'key',
    NAME = 'name',
    NIT = 'nit',
    PHONE = 'phone1',
    ADDRESS = 'address',
    EMAIL = 'email',
    CREDIT_DAYS = 'creditDays',
    STATUS = 'status',
}

export const TABLE_COLUMNS = [
    {
        title: descriptions.TABLE_COLUMNS.ID,
        dataIndex: ColumnsKeys.NO,
        key: ColumnsKeys.NO,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.NAME,
        dataIndex: ColumnsKeys.NAME,
        key: ColumnsKeys.NAME,
    },
    {
        title: descriptions.TABLE_COLUMNS.NIT,
        dataIndex: ColumnsKeys.NIT,
        key: ColumnsKeys.NIT,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.PHONE,
        dataIndex: ColumnsKeys.PHONE,
        key: ColumnsKeys.PHONE,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.ADDRESS,
        dataIndex: ColumnsKeys.ADDRESS,
        key: ColumnsKeys.ADDRESS,
        render: (address: string) => {
            const length = 30;
            const trimmedString = address && address.length > length ? `${address.substring(0, length)}...` : address;
            return <span>{trimmedString}</span>
        },
    },
    {
        title: descriptions.TABLE_COLUMNS.EMAIL,
        dataIndex: ColumnsKeys.EMAIL,
        key: ColumnsKeys.EMAIL,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.CREDI_DAYS,
        dataIndex: ColumnsKeys.CREDIT_DAYS,
        key: ColumnsKeys.CREDIT_DAYS,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.STATUS,
        dataIndex: ColumnsKeys.STATUS,
        key: ColumnsKeys.STATUS,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => value === 1 ? commonDescriptions.ACTIVE_STATUS : commonDescriptions.INACTIVE_STATUS,
    },
];