import { call, put, takeLatest, } from 'redux-saga/effects'
import { Urls } from 'src/common/urls'
import * as Actions from './action'
import * as ActionTypes from './actionTypes'
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider'
import { getErrorFromResponse } from 'src/features/Security/networking/util'

function* getSuppliersApi() {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.SUPPLIER.GET_RECORDS())
        yield put(Actions.getSuppliersApiSuccess(response.data))
    } catch (err) {
        yield put(Actions.getSuppliersApiError(err));
    }
}

function* upsertSuppliersApi(action: Actions.UpsertSupplierAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.SUPPLIER.UPSERT, action.supplier)
        yield put(Actions.upsertSupplierApiSuccess(response.data))
    } catch (err) {
        yield put(Actions.upsertSuppliersApiError(getErrorFromResponse(err)));
    }
}

export default function* securitySagas() {
    yield takeLatest(ActionTypes.GET_SUPPLIERS_API, getSuppliersApi)
    yield takeLatest(ActionTypes.UPSERT_SUPPLIER_API, upsertSuppliersApi)
}