import jsPDF from 'jspdf';
import { SystemConstants } from 'src/common/constants';
import { Company } from 'src/common/models/company';
import dayjs from "dayjs"
import { SystemDescriptions } from 'src/common/descriptions';
import { WarehouseStock } from 'src/common/models/warehouseStock';
import { formatToCurrency } from 'src/common/util';

interface Props {
    company: Company
    date: string
    data: WarehouseStock[]
    isStockValuated?: boolean
}

const WarehouseStockReport = (props: Props) => {

    const pdf = new jsPDF();

    const descriptions = SystemDescriptions.PAGES.PRODUCT.WAREHOUSE_STOCK
    const fontConstants = SystemConstants.REPORTS.FONT

    const buildHeader = () => {
        pdf.setProperties({
            title: descriptions.TITLE,
        })

        pdf.setFontSize(fontConstants.SIZE);
        pdf.setFont(fontConstants.FAMILY, fontConstants.BOLD);
        pdf.text(descriptions.TITLE, 150, 12);

        pdf.setLineWidth(0.1);

        pdf.setDrawColor(200, 200, 200);
        pdf.line(10, 18, 200, 18)
        pdf.text(props.company.tradeName, 13, 23)
        pdf.setFont(fontConstants.FAMILY, fontConstants.NORMAL);
        pdf.text("NIT: " + props.company.nit, 13, 28)
        pdf.text(props.company.address, 13, 32)

        pdf.setFont(fontConstants.FAMILY, fontConstants.BOLD)
        pdf.text(`${descriptions.REPORT.DATE}      :`, 130, 23)
        pdf.text(``, 130, 27)
        pdf.text(`${descriptions.REPORT.PRINTED_AT}    :`, 130, 31)
        pdf.setFont(fontConstants.FAMILY, fontConstants.NORMAL)
        pdf.text(props.date, 155, 23)
        pdf.text('', 155, 27)
        pdf.text(dayjs().format(SystemConstants.DATETIME_FORMAT_DISPLAY), 155, 31)
        pdf.line(10, 34, 200, 34)
    }

    const buildDetailData = () => {

        const itemDetailsRows = !props.isStockValuated
            ? props.data.map((item, index) => [
                (index + 1).toString(),
                item.productId,
                item.product,
                item.meassure,
                item.stock,
            ])
            : props.data.map((item, index) => [
                (index + 1).toString(),
                item.productId,
                item.product,
                item.meassure,
                item.stock,
                formatToCurrency(item.productCoste.toString()),
                formatToCurrency(item.stockValue.toString()),
            ]);

        const itemDetailsHeaders = [
            descriptions.TABLE_COLUMNS.KEY,
            descriptions.TABLE_COLUMNS.PRODUCT_ID,
            descriptions.TABLE_COLUMNS.MEASSURE,
            descriptions.TABLE_COLUMNS.DESCRIPTION,
            descriptions.TABLE_COLUMNS.STOCK,
        ];

        if (props.isStockValuated) {
            itemDetailsHeaders.push(descriptions.TABLE_COLUMNS.COSTE)
            itemDetailsHeaders.push(descriptions.TABLE_COLUMNS.VALUE)
        }

        const columnWidths = props.isStockValuated
            ? [15, 20, 70, 20, 23, 23, 23]
            : [15, 30, 95, 30, 23];

        const headerStyles = {
            fillColor: [240, 240, 240],
            textColor: [0],
            fontFamily: fontConstants.FAMILY,
            fontStyle: fontConstants.BOLD,
        };

        pdf.setFont(fontConstants.FAMILY);
        const itemDetailsYStart = 38;
        (pdf as any).autoTable({
            head: [itemDetailsHeaders],
            body: itemDetailsRows,
            startY: itemDetailsYStart,
            headStyles: {
                fillColor: headerStyles.fillColor,
                textColor: headerStyles.textColor,
                fontStyle: headerStyles.fontStyle,
                fontSize: fontConstants.SIZE,
                font: fontConstants.FAMILY,
                halign: 'left',
            },
            columnStyles: props.isStockValuated
                ? {
                    0: { cellWidth: columnWidths[0] },
                    1: { cellWidth: columnWidths[1] },
                    2: { cellWidth: columnWidths[2] },
                    3: { cellWidth: columnWidths[3] },
                    4: { cellWidth: columnWidths[4] },
                    5: { cellWidth: columnWidths[5] },
                    6: { cellWidth: columnWidths[6] },
                }
                : {
                    0: { cellWidth: columnWidths[0] },
                    1: { cellWidth: columnWidths[1] },
                    2: { cellWidth: columnWidths[2] },
                    3: { cellWidth: columnWidths[3] },
                    4: { cellWidth: columnWidths[4] },
                },
            alternateRowStyles: { fillColor: [255, 255, 255] },
            bodyStyles: {
                fontSize: fontConstants.SIZE,
                font: fontConstants.FAMILY,
                cellPadding: { top: 1, right: 5, bottom: 1, left: 2 },
                textColor: [0, 0, 0],
                rowPageBreak: 'avoid',
            },
            margin: { top: 10, left: 13 },
        });

    }

    const showReport = () => {
        const pdfDataUri = pdf.output('datauristring');
        const newTab = window.open();
        newTab?.document.write(`<iframe width='100%' height='100%' src='${pdfDataUri}'></iframe>`);
    }

    buildHeader()
    buildDetailData()
    showReport()
}

export default WarehouseStockReport