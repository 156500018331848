import { ApiActionTypes, buildApiTypeAction } from "src/common/apiLoader/apiLoaderUtils";

const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMERS_API = buildApiTypeAction(GET_CUSTOMERS, ApiActionTypes.REQUEST)
export const GET_CUSTOMERS_API_SUCCESS = buildApiTypeAction(GET_CUSTOMERS, ApiActionTypes.SUCCESS)
export const GET_CUSTOMERS_API_ERROR = buildApiTypeAction(GET_CUSTOMERS, ApiActionTypes.ERROR)

const UPSERT_CUSTOMER = 'UPSERT_CUSTOMER';
export const UPSERT_CUSTOMER_API = buildApiTypeAction(UPSERT_CUSTOMER, ApiActionTypes.REQUEST)
export const UPSERT_CUSTOMER_API_SUCCESS = buildApiTypeAction(UPSERT_CUSTOMER, ApiActionTypes.SUCCESS)
export const UPSERT_CUSTOMER_API_ERROR = buildApiTypeAction(UPSERT_CUSTOMER, ApiActionTypes.ERROR)

const GET_CUSTOMER_BY_ID = 'GET_CUSTOMER_BY_ID';
export const GET_CUSTOMER_BY_ID_API = buildApiTypeAction(GET_CUSTOMER_BY_ID, ApiActionTypes.REQUEST)
export const GET_CUSTOMER_BY_ID_API_SUCCESS = buildApiTypeAction(GET_CUSTOMER_BY_ID, ApiActionTypes.REQUEST)
export const GET_CUSTOMER_BY_ID_API_ERROR = buildApiTypeAction(GET_CUSTOMER_BY_ID, ApiActionTypes.REQUEST)