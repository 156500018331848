import { call, put, takeLatest, } from 'redux-saga/effects'
import { Urls } from 'src/common/urls'
import * as Actions from './action'
import * as ActionTypes from './actionTypes'
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider'
import { getErrorFromResponse } from 'src/features/Security/networking/util'
import { GetStockRegularizationRecordsFilters } from 'src/common/models/stockRegularization'

const buildQueryString = (filters?: GetStockRegularizationRecordsFilters) => {
    const filtersList: string[] = []
    if (filters?.dateFrom && filters.dateTo) {
        filtersList.push(`dateFrom=${filters.dateFrom}&dateTo=${filters.dateTo}`)
    }

    return filtersList.length > 0
        ? `?${filtersList.join('&')}`
        : ``
}

function* getRegulariationRecordsApi(action: Actions.GetRegulariationRecordsAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.REGULARIZATION.GET_RECORDS(buildQueryString(action.filters)))
        yield put(Actions.getRegularizationRecordsSuccess(response.data))
    } catch (err) {
        yield put(Actions.getRegularizationRecordsError(getErrorFromResponse(err)));
    }
}

function* createRegulariationApi(action: Actions.CreateRegulariationAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.REGULARIZATION.CREATE, action.regularizations)
        yield put(Actions.createRegularizationSuccess(response.data))
    } catch (err) {
        yield put(Actions.createRegularizationError(getErrorFromResponse(err)));
    }
}

export default function* saleSagas() {
    yield takeLatest(ActionTypes.GET_REGULARIZATION_RECORDS_API, getRegulariationRecordsApi)
    yield takeLatest(ActionTypes.CREATE_REGULARIZATION_API, createRegulariationApi)
}