import { SearchableTable } from "src/components/SearchableTable"
import { TABLE_COLUMNS } from "./config"
import { ProductSettingsError, ProductSettingsType } from "../../state/types"
import { ProductBrand } from "src/common/models/productBrand"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_PRODUCT_SETTINGS_API } from "../../state/actionTypes"
import { SystemDescriptions } from "src/common/descriptions"
import { CreateProductPropertyModal } from "../CreateProductPropertyModal"
import { ProductProperty } from ".."
import { useState } from "react"
import { getProductSettings } from "../../state/action"

interface ReduxProps {
    brands?: ProductBrand[]
    error?: ProductSettingsError
    isGettingSettings: boolean
}

export const ProductBrands = () => {
    const dispatch = useDispatch()

    const [openModal, setOpenModal] = useState<boolean>(false)

    const descriptions = SystemDescriptions.PAGES.PRODUCT.PRODUCT_PROPERTIES.BRANDS.RECORDS

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        brands: state.productSettings.brands,
        error: state.productSettings.error,
        isGettingSettings: serviceLoading(state, [GET_PRODUCT_SETTINGS_API]),
    }))

    const renderCreateModal = () => {
        return <CreateProductPropertyModal
            type={ProductProperty.BRAND}
            open={openModal}
            onCancel={() => { setOpenModal(false) }}
            onFinish={() => {
                setOpenModal(false)
                dispatch(getProductSettings([ProductSettingsType.BRAND]))
            }}
        />
    }

    const handleActionButtonClick = () => {
        setOpenModal(true)
    }

    return (
        <>
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.brands || []}
                tableColumns={TABLE_COLUMNS}
                showLoader={reduxProps.isGettingSettings}
                actionButtonLabel={descriptions.TABLE.ACTION_BUTTON}
                showActionButton={true}
                actionButtonOnClick={handleActionButtonClick}
            />
            {renderCreateModal()}
        </>
    )
}
