import { SystemConstants } from "../constants";

export enum ApiActionTypes {
    REQUEST = "REQUEST",
    SUCCESS = "SUCCES",
    ERROR = "ERROR",
}

export const buildApiTypeAction = (action: string, type: ApiActionTypes): string => {
    switch (type) {
        case ApiActionTypes.REQUEST:
            return action + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
        case ApiActionTypes.SUCCESS:
            return action + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
        case ApiActionTypes.ERROR:
            return action + SystemConstants.API_LOADER.ERROR_SUFFIX;
        default:
            return '';
    }
}