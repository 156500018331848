import { Modal } from "antd"
import { ProductProperty } from ".."
import { ProductBrandForm } from "./ProductBrandForm"
import { useDispatch, useSelector } from "react-redux"
import { upsertProductBrandApi, upsertProductCategoryApi, upsertProductSubBrandApi } from "../../state/action"
import { ProductBrand } from "src/common/models/productBrand"
import { ProductSettingsType } from "../../state/types"
import { RootState } from "src/state/reducer"
import { useEffect } from "react"
import { SystemDescriptions } from "src/common/descriptions"
import { ProductCategoryForm } from "./ProductCategoryForm"
import { ProductCategory } from "src/common/models/productCategory"
import { ProductSubBrandForm } from "./ProductSubBrandForm"
import { ProductSubBrand } from "src/common/models/productSubBrand"

interface ReduxProps {
    upsertProductSettingSuccess?: ProductSettingsType
}

interface Props {
    type: ProductProperty
    open: boolean
    onFinish: () => void
    onCancel: () => void
}

export const CreateProductPropertyModal = (props: Props) => {
    const dispatch = useDispatch()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        upsertProductSettingSuccess: state.productSettings.upsertProductSettingSuccess,
    }))

    const descriptions = SystemDescriptions.PAGES.PRODUCT.PRODUCT_PROPERTIES

    useEffect(() => {
        if (reduxProps.upsertProductSettingSuccess) {
            props.onFinish()
        }
    }, [reduxProps.upsertProductSettingSuccess])


    const handleAcceptForm = (brand: Partial<ProductBrand>) => {
        dispatch(upsertProductBrandApi(brand as ProductBrand))
    }

    const handleCategoryAcceptForm = (category: Partial<ProductCategory>) => {
        dispatch(upsertProductCategoryApi(category as ProductCategory))
    }

    const handleSubBrandAcceptForm = (subBrand: Partial<ProductSubBrand>) => {
        dispatch(upsertProductSubBrandApi(subBrand as ProductSubBrand))
    }

    const renderModalForm = () => {
        switch (props.type) {
            case ProductProperty.BRAND:
                return <ProductBrandForm
                    onCancel={props.onCancel}
                    onFinish={handleAcceptForm}
                />

            case ProductProperty.SUBBRAND:
                return <ProductSubBrandForm
                    onCancel={props.onCancel}
                    onFinish={handleSubBrandAcceptForm}
                />

            case ProductProperty.CATEGORY:
                return <ProductCategoryForm
                    onCancel={props.onCancel}
                    onFinish={handleCategoryAcceptForm}
                />

            default:
                return null
        }
    }

    const getModalTitle = (): string => {
        let title: string = ''

        switch (props.type) {
            case ProductProperty.BRAND:
                title = descriptions.BRANDS.UPSERT.TITLE
                break
            case ProductProperty.SUBBRAND:
                title = descriptions.SUBBRANDS.UPSERT.TITLE
                break
            case ProductProperty.CATEGORY:
                title = descriptions.CATEGORIES.UPSERT.TITLE
                break
            default:
                title = ''
        }

        return title
    }

    return (
        <Modal
            title={getModalTitle()}
            centered
            open={props.open}
            onOk={() => { }}
            onCancel={props.onCancel}
            footer={null}
            closable
        >
            {renderModalForm()}
        </Modal>
    )
}
