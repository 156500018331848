import { Languages } from "src/common/descriptions/types";
import { CreateRegularizationDescriptions } from "./types";

const DescriptionsEnUs: CreateRegularizationDescriptions = {
    TITLE: "Create Regularization",
    SAVE_BUTTON: "Save",
    CANCEL_BUTTON: "Cancel",
    ACCEPT_BUTTON: "Accept",
    CONFIRM_MODAL: {
        TITLE: 'Save Regularization?',
        DESCRIPTION: "Operation will not be reverted.",
        ACTION_BUTTON: "Save",
        CANCEL_BUTTON: "Go Back",
    },
    CANCEL_MODAL: {
        TITLE: "Cancel Regularization input?",
        DESCRIPTION: "All data will be lost.",
        ACTION_BUTTON: "Yes, Cancel",
        CANCEL_BUTTON: "Keep working",
    },
    SUCCESS_MODAL: {
        TITLE: "Regularization Results",
        PRIMARY_LABEL: "Accept",
        SECONDARY_LABEL: "Accept",
        DESCRIPTION: "Product regularization created sucessfully",
    },
    FORM: {
        DESCRIPTION: {
            LABEL: "Product Name",
            PLACEHOLDER: "Product Name",
        },
        PRODUCT: {
            LABEL: "Product",
            PLACEHOLDER: "Product",
        },
        MEASSURE: {
            LABEL: "Meassure",
            PLACEHOLDER: "Select Meassure",
        },
        TYPE: {
            LABEL: "Type",
            PLACEHOLDER: "Select type",
        },
        QTY: {
            LABEL: "Qty",
            PLACEHOLDER: "Input qty",
        },
        PREVIOUS_STOCK: {
            LABEL: "Previous Stock",
            PLACEHOLDER: "0",
        },
        NEW_STOCK: {
            LABEL: "New Stock",
            PLACEHOLDER: "0",
        },
        COMMENT: {
            LABEL: "Comment",
            PLACEHOLDER: "Input comment",
        },
        ERRORS: {
            INVALID_QTY: "Invalid qty",
            NOT_ENOUGH_STOCK: "Not enough stock",
        },
    },
    ITEMS: {
        PRODUCT_ID: "ID",
        DESCRIPTION: "Description",
        MEASSURE: "Meassure",
        TYPE: "Type",
        PREVIOUS: "Previous",
        QTY: "Qty",
        NEW: "New",
        COMMENT: "Comment",
    },
    TYPE_ADD: "Add",
    TYPE_REST: "Rest",
    MAIN_ACTION_TITLE: "Creating Product Regularization",
    MAIN_ACTION_DESCRIPTION: "Please wait...",
}

const DescriptionsEsUs: CreateRegularizationDescriptions = {
    TITLE: "Crear Regularización",
    SAVE_BUTTON: "Guardar",
    CANCEL_BUTTON: "Cancelar",
    ACCEPT_BUTTON: "Aceptar",
    CONFIRM_MODAL: {
        TITLE: 'Guardar Regularización con los datos ingresados?',
        DESCRIPTION: "La operación no podrá ser revertida.",
        ACTION_BUTTON: "Guardar",
        CANCEL_BUTTON: "Regresar",
    },
    CANCEL_MODAL: {
        TITLE: "Cancelar ingreso de Regularización?",
        DESCRIPTION: "La información ingresada sera borrada.",
        ACTION_BUTTON: "Si, Cancelar",
        CANCEL_BUTTON: "Seguir ingresando",
    },
    SUCCESS_MODAL: {
        TITLE: "Resultados de Regularización",
        PRIMARY_LABEL: "Aceptar",
        SECONDARY_LABEL: "Aceptar",
        DESCRIPTION: "Regularización creada con éxito",
    },
    FORM: {
        PRODUCT: {
            LABEL: "Producto",
            PLACEHOLDER: "Seleccione Producto",
        },
        DESCRIPTION: {
            LABEL: "Nombre Producto",
            PLACEHOLDER: "Nombre Producto",
        },
        MEASSURE: {
            LABEL: "Medida",
            PLACEHOLDER: "Seleccione medida",
        },
        TYPE: {
            LABEL: "Tipo",
            PLACEHOLDER: "Seleccione tipo",
        },
        QTY: {
            LABEL: "Cant",
            PLACEHOLDER: "Ingrese cantidad",
        },
        PREVIOUS_STOCK: {
            LABEL: "Stock Anterior",
            PLACEHOLDER: "0",
        },
        NEW_STOCK: {
            LABEL: "Nuevo Stock",
            PLACEHOLDER: "0",
        },
        COMMENT: {
            LABEL: "Observaciones",
            PLACEHOLDER: "Ingrese observaciones",
        },
        ERRORS: {
            INVALID_QTY: "Cantidad inválida",
            NOT_ENOUGH_STOCK: "Existencia insuficiente",
        },
    },
    ITEMS: {
        PRODUCT_ID: "ID",
        DESCRIPTION: "Descripción",
        MEASSURE: "Presentación",
        TYPE: "Tipo",
        PREVIOUS: "Anterior",
        QTY: "Cant",
        NEW: "Nuevo",
        COMMENT: "Observaciones",
    },
    TYPE_ADD: "Agregar",
    TYPE_REST: "Restar",
    MAIN_ACTION_TITLE: "Creando Regularización",
    MAIN_ACTION_DESCRIPTION: "Por favor espere...",
}

export const createRegularizationDictionary = {
    [Languages.ENGLISH_US]: DescriptionsEnUs,
    [Languages.SPANISH_US]: DescriptionsEsUs,
}