export interface StockRegularization {
    id: number
    datetime: string
    productId: number
    product: string
    meassure: string
    qty: number
    coste: number
    userId: number
    username: string
    comment: string
    warehouseId: number
    previousStock: number
    newStock: number
    errorMessage?: string
}

export interface GetStockRegularizationRecordsFilters {
    dateFrom?: string
    dateTo?: string
}

export interface StockRegularizationTransaction {
    id?: number
    productId: number
    product: string
    meassure: string
    qty: number
    comment: string
    previousStock: number
    newStock: number
    meassureId: number
    type: RegularizationType
    coste: number
    warehouseId: number
}

export enum RegularizationType {
    ADD = 'ADD',
    REST = 'REST',
}