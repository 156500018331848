import { PageCard } from "src/common/styles/styles"
import { useEffect, useState } from "react"
import { ColumnsKeys, TABLE_COLUMNS } from "./config"
import { Product } from "src/common/models/product"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { GET_PRODUCTS_API } from "../state/actionTypes"
import { getProductsApi } from "../state/action"
import { Button, Modal, Space, Tooltip } from "antd"
import { ProductPresentation } from "src/common/models/productPresentation"
import { useNavigate } from "react-router"
import { Urls } from "src/common/urls"
import { searchableTableUtils } from "src/components/SearchableTable/utils"
import { SearchableTable } from "src/components/SearchableTable"
import { SystemDescriptions } from "src/common/descriptions"
import { EditOutlined, ProfileOutlined } from '@ant-design/icons'
import { ProductPresentationsList } from "./ProductPresentationsList"
import { User } from "src/common/models/user"
import useUserPermission from "src/hooks/useUserPermissions"

interface ReduxProps {
    isLoadingData: boolean
    products: Product[]
    user?: User
}

export const Products = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { isUserAllowedToPerformAdminActions: userAdminActions } = useUserPermission()

    const [selectedPresentations, setSelectedPresentations] = useState<ProductPresentation[]>([])
    const [selectedProductName, setselectedProductName] = useState<string>("")
    const [buttonActive, setButtonActive] = useState<boolean>(true)

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_PRODUCTS_API]),
        products: state.product.products,
        user: state.security.user,
    }))

    useEffect(() => {
        const status = buttonActive ? "ACTIVE" : "INACTIVE"
        dispatch(getProductsApi({ status }))
    }, [buttonActive])

    const descriptions = SystemDescriptions.PAGES.PRODUCT.PRODUCTS
    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    const tableColumns = [
        ...TABLE_COLUMNS,
        {
            title: descriptions.TABLE_COLUMNS.PRESENTATIONS,
            dataIndex: ColumnsKeys.PRESENTATIONS,
            key: ColumnsKeys.PRESENTATIONS,
            align: searchableTableUtils.alignment.centerAlign,
            render: (_, record: Product) => {
                const handlePresentationsClick = () => {
                    setSelectedPresentations(record?.presentations.sort((a, b) => a.qty - b.qty))
                    setselectedProductName(record.name)
                }

                return <Button
                    onClick={handlePresentationsClick}
                    type="link"
                >
                    {record?.presentations?.length ?? 0}
                </Button>
            },
        },
        {
            title: descriptions.TABLE_COLUMNS.ACTIONS,
            dataIndex: ColumnsKeys.ACTIONS,
            key: ColumnsKeys.ACTIONS,
            align: searchableTableUtils.alignment.centerAlign,
            render: (_, record: Product) => {
                const handleEditClick = (productId: number) => {
                    navigate(Urls.FRONTEND.PRODUCT.EDIT_PAGE(productId))
                }

                const handleViewClick = (productId: number) => {
                    navigate(Urls.FRONTEND.PRODUCT.DETAIL_PAGE(productId))
                }

                return <Space size="middle">
                    <Tooltip title={commonDescriptions.ACTIONS.VIEW} >
                        <Button type="primary" ghost shape="circle" size="small"
                            icon={<ProfileOutlined rev={undefined} />}
                            onClick={() => handleViewClick(record.id!)}
                        />
                    </Tooltip>
                    {
                        userAdminActions
                            ?
                            <Tooltip title={commonDescriptions.ACTIONS.EDIT} >
                                <Button type="primary" ghost shape="circle" size="small"
                                    icon={<EditOutlined rev={undefined} />}
                                    onClick={() => handleEditClick(record.id!)}
                                />
                            </Tooltip>
                            : null
                    }
                </Space>
            },
        },
    ]

    const handleActionButtonClick = () => {
        navigate(Urls.FRONTEND.PRODUCT.CREATE)
    }

    const renderExtraContent = () => {

        const handleButtonClick = () => {
            setButtonActive(!buttonActive)
        }

        return <Button
            danger={buttonActive}
            size="small"
            onClick={handleButtonClick}
            type="link"
        >
            {buttonActive ? descriptions.BUTTON_INACTIVES : descriptions.BUTTON_ACTIVES}
        </Button>
    }

    const renderContent = () => (
        < PageCard
            size="small"
            title={buttonActive ? descriptions.TITLE : descriptions.TITLE_INACTIVES}
            extra={renderExtraContent()}
        >
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.products}
                tableColumns={tableColumns}
                showLoader={reduxProps.isLoadingData}
                actionButtonLabel={descriptions.TABLE.ACTION_BUTTON}
                showActionButton={userAdminActions}
                actionButtonOnClick={handleActionButtonClick}
            />
        </PageCard >
    )

    const renderModalPresentations = () => {
        return <Modal
            title={`${descriptions.TABLE_COLUMNS.PRESENTATIONS} - ${selectedProductName} `}
            centered
            open={selectedPresentations.length > 0}
            onOk={() => { setSelectedPresentations([]) }}
            onCancel={() => { setSelectedPresentations([]) }}
            footer={null}
            closable
        >
            <ProductPresentationsList
                productPresentations={selectedPresentations}
            />
        </Modal>
    }

    return (
        <>
            {renderContent()}
            {renderModalPresentations()}
        </>
    )
}
