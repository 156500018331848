import { Supplier } from "src/common/models/supplier"
import { SupplierError } from "../../state/types"
import { Alert, Form, Input, Row, Select } from "antd"
import { useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { Col12, Col8 } from "src/components/Columns"
import { SystemDescriptions } from "src/common/descriptions"
import { getInputRequiredRule } from "src/common/util"
import { FormActionButtons } from "src/components/FormActionButtons"

interface ReduxProps {
    error?: SupplierError
}

interface Props {
    initialValues?: Supplier
    onFinish: (supplier: Partial<Supplier>) => void
    onCancel: () => void
}

export const SupplierForm = (props: Props) => {

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        error: state.supplier.error,
    }))

    const descriptions = SystemDescriptions.PAGES.SUPPLIER.CREATE
    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    const [form] = Form.useForm();

    const onFinish = (formValues) => {
        const supplier: Supplier = {
            name: formValues.name,
            nit: formValues.nit,
            address: formValues.address,
            email: formValues.email,
            phone1: formValues.phone,
            phone2: "0",
            creditDays: formValues.creditDays,
            status: formValues.status,
        }

        if (props.initialValues && props.initialValues.id) {
            supplier.id = props.initialValues.id
        }
        props.onFinish(supplier)
    }

    const getButtonStatus = (): boolean => {
        return props.initialValues
            ? false
            : !form.isFieldsTouched(true) ||
            form.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0
    }

    const handleCancelClick = () => {
        props.onCancel()
    }

    const renderButtons = () => (
        <FormActionButtons
            cancelText={descriptions.CANCEL_BUTTON}
            actionText={descriptions.SAVE_BUTTON}
            actionDisabled={getButtonStatus}
            onCancel={handleCancelClick}
        />
    )

    const renderForm = () => (
        <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            autoComplete={'none'}
            onFinish={onFinish}
        >
            <Row gutter={[16, 8]}>
                <Col12>
                    <Form.Item
                        label={descriptions.FORM.NAME.LABEL}
                        name={"name"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.NAME.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.NAME.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col12>
                <Col12>
                    <Form.Item
                        label={descriptions.FORM.ADDRESS.LABEL}
                        name={"address"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.ADDRESS.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.ADDRESS.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col12>
                <Col8>
                    <Form.Item
                        label={descriptions.FORM.NIT.LABEL}
                        name={"nit"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.NIT.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.NIT.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col8>
                <Col8>
                    <Form.Item
                        label={descriptions.FORM.EMAIL.LABEL}
                        name={"email"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.EMAIL.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.EMAIL.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col8>
                <Col8>
                    <Form.Item
                        label={descriptions.FORM.PHONE.LABEL}
                        name={"phone"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.PHONE.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.PHONE.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col8>
                <Col8>
                    <Form.Item
                        label={descriptions.FORM.CREDIT_DAYS.LABEL}
                        name={"creditDays"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.CREDIT_DAYS.PLACEHOLDER),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.CREDIT_DAYS.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col8>
                <Col8>
                    <Form.Item
                        label={descriptions.FORM.STATUS.LABEL}
                        name={"status"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.STATUS.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            options={[
                                {
                                    value: 1,
                                    label: commonDescriptions.ACTIVE_STATUS
                                },
                                {
                                    value: 2,
                                    label: commonDescriptions.INACTIVE_STATUS,
                                },
                            ]}
                            placeholder={descriptions.FORM.STATUS.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col8>
            </Row>
            {
                reduxProps.error &&
                <Alert
                    message={"ERROR"}
                    showIcon
                    description={reduxProps.error?.detail.message ?? commonDescriptions.FORM.UNKNOWN_ERROR}
                    type="error"
                    style={{
                        marginBottom: 12,
                    }}
                />
            }
            {renderButtons()}
        </Form>
    )

    return (
        renderForm()
    )
}
