import { PageCard } from "src/common/styles/styles"
import InnerLoader from "src/components/InnerLoader"
import { SupplierError } from "../state/types"
import { Supplier } from "src/common/models/supplier"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { UPSERT_SUPPLIER_API } from "../state/actionTypes"
import { SupplierForm } from "./SupplierForm"
import { Loader } from "src/components/Loader"
import { SystemDescriptions } from "src/common/descriptions"
import { CancelConfirmationModal } from "src/common/descriptions/components/cancelConfirmationModal"
import { ModalSuccess } from "src/components/ModalSuccess"
import { useState } from "react"
import { upsertSupplierApi } from "../state/action"
import { useNavigate } from "react-router"
import { Urls } from "src/common/urls"

interface ReduxProps {
    error?: SupplierError
    isGettingSupplier: boolean
    isUpsertingSupplier: boolean
    upsertSupplierSuccess: boolean
    currentSupplier?: Supplier
}

export const CreateSupplier = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [modalConfirmationOpen, setModalConfirmationOpen] = useState<boolean>(false)
    const [modalCancelOpen, setModalCancelOpen] = useState<boolean>(false)
    const [formValues, setFormValues] = useState<Supplier | undefined>()
    const [actionButtonClicked, setActionButtonClicked] = useState<boolean>(false)

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        error: state?.supplier.error,
        isGettingSupplier: false,
        isUpsertingSupplier: serviceLoading(state, [UPSERT_SUPPLIER_API]),
        upsertSupplierSuccess: state.supplier.upsertSupplierSuccess,
    }))

    const descriptions = SystemDescriptions.PAGES.SUPPLIER.CREATE

    const renderLoader = () => (
        (reduxProps.isUpsertingSupplier)
        && <Loader
            isVisible={true}
            title={descriptions.MAIN_ACTION_TITLE}
            description={descriptions.MAIN_ACTION_DESCRIPTION}
        />
    )

    const handleConfirmationAccept = () => {
        setModalConfirmationOpen(false)
        setActionButtonClicked(true)
        dispatch(upsertSupplierApi(formValues as Supplier))
    }

    const handleCancelAccept = () => {
        navigate(Urls.FRONTEND.SUPPLIER.RECORDS)
    }

    const renderModals = () => (
        <>
            <CancelConfirmationModal
                isVisible={modalConfirmationOpen}
                onAccept={handleConfirmationAccept}
                onCancel={() => {
                    setFormValues(undefined)
                    setModalConfirmationOpen(false)
                }}
                title={descriptions.CONFIRM_MODAL.TITLE}
                okText={descriptions.CONFIRM_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CONFIRM_MODAL.CANCEL_BUTTON}
                description={descriptions.CONFIRM_MODAL.DESCRIPTION}
            />
            <CancelConfirmationModal
                isVisible={modalCancelOpen}
                onAccept={handleCancelAccept}
                onCancel={() => { setModalCancelOpen(false) }}
                title={descriptions.CANCEL_MODAL.TITLE}
                okText={descriptions.CANCEL_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CANCEL_MODAL.CANCEL_BUTTON}
                description={descriptions.CANCEL_MODAL.DESCRIPTION}
            />
            <ModalSuccess
                isVisible={(reduxProps.upsertSupplierSuccess) && actionButtonClicked}
                title={descriptions.SUCCESS_MODAL.TITLE}
                description={descriptions.SUCCESS_MODAL.DESCRIPTION}
                onPrimaryAction={handleCancelAccept}
                onSecondaryActiont={handleCancelAccept}
                primaryLabel={descriptions.SUCCESS_MODAL.PRIMARY_LABEL}
                secondaryLabel={descriptions.SUCCESS_MODAL.SECONDARY_LABEL}
                hideSecondaryButton={true}
            />
        </>
    )

    const onFinish = (supplier: Partial<Supplier>) => {
        setFormValues(supplier as Supplier)
        setModalConfirmationOpen(true)
    }

    const onCancel = () => {
        setModalCancelOpen(true)
    }

    return (
        <>
            <PageCard
                size="small"
                title={descriptions.TITLE}
            >
                {
                    reduxProps.isGettingSupplier
                        ? <InnerLoader />
                        : <SupplierForm
                            onFinish={onFinish}
                            onCancel={onCancel}
                        />
                }
            </PageCard>
            {renderModals()}
            {renderLoader()}
        </>
    )
}
