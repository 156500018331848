import { Tabs } from "antd";
import { CreateProductForm } from "../CreateProduct/CreateProductForm";
import { Product } from "src/common/models/product";
import { useParams } from "react-router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductByIdApi } from "../state/action";
import { ProductError, ProductErrorOrigin } from "../state/types";
import { RootState } from "src/state/reducer";
import { serviceLoading } from "src/common/apiLoader/state/selection";
import { GET_PRODUCT_BY_ID_API } from "../state/actionTypes";
import InnerLoader from "src/components/InnerLoader";
import { PageCard } from "src/common/styles/styles";

interface ReduxProps {
    error?: ProductError
    isGettingProduct: boolean
    currentProduct?: Product
}

export const ProductDetail = () => {
    const dispatch = useDispatch()

    const { id: productId } = useParams<{ id: string }>();

    useEffect(() => {
        if (!productId) {
            return
        }

        dispatch(getProductByIdApi(parseInt(productId)))
    }, [productId])

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isGettingProduct: serviceLoading(state, [GET_PRODUCT_BY_ID_API]),
        currentProduct: state.product.currentProduct,
        error: state.product.error,
    }))

    const renderDataSection = () => (
        reduxProps.isGettingProduct
            ? <InnerLoader />
            : (reduxProps.error && reduxProps.error.type === ProductErrorOrigin.GET_PRODUCT_BY_ID) || !reduxProps.currentProduct
                ? <>An error ocurred</>
                : <CreateProductForm
                    onFinish={function (product: Partial<Product>): void {
                        throw new Error("Function not implemented.");
                    }} onCancel={function (): void {
                        throw new Error("Function not implemented.");
                    }}
                    initialValues={reduxProps.currentProduct}
                    readOnly
                />
    )

    return (
        <PageCard
            title={'Product Detail'}
        >
            <Tabs
                defaultActiveKey="1"
                size={"small"}
                items={[
                    {
                        label: "Data",
                        key: "Data",
                        children: renderDataSection(),
                    },
                    {
                        label: "Purchase Records",
                        key: "PurchaseRecords",
                        children: <>--</>,
                    },
                ]}
            />
        </PageCard>
    )
}
