import { GetCompanyByIdSuccessAction } from "./action";
import { GET_COMPANY_API, GET_COMPANY_API_ERROR, GET_COMPANY_API_SUCCESS, UPDATE_SETTINGS } from "./actionTypes";
import { Company } from "../models/company";
import { CommonError, CommonErrorOrigin } from "./types";
import { Languages } from "../descriptions/types";
import { getSessionData } from "src/session/sessionStore";
import * as Actions from "./action";
import { ErrorAction } from "../apiLoader/state/actions";

export interface CommonState {
    company?: Company
    error?: CommonError
    language: Languages
}

const getSysLang = (): Languages => {
    const language = getSessionData().language
    return language && language.trim().length > 0 ? language : Languages.SPANISH_US
}

const initialState: CommonState = {
    language: getSysLang(),
}

const commonReducer = (state: CommonState = initialState, action: any): CommonState => {
    switch (action.type) {
        case GET_COMPANY_API: {
            return {
                ...state,
                error: undefined,
            }
        }
        case GET_COMPANY_API_SUCCESS: {
            const successAction = action as GetCompanyByIdSuccessAction;

            return {
                ...state,
                company: successAction.company,
            }
        }
        case GET_COMPANY_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: CommonErrorOrigin.GET_COMPANY_BY_ID,
                    detail: errorAction.error
                }
            }
        }
        case UPDATE_SETTINGS: {
            const updateAction = action as Actions.UpdateSettingsAction

            return {
                ...state,
                ...updateAction.settings,
            }
        }
    }
    return state;
};

export default commonReducer;