import { Alert, Form, Input, Row } from "antd"
import { useSelector } from "react-redux"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { SystemDescriptions } from "src/common/descriptions"
import { ProductBrand } from "src/common/models/productBrand"
import { ProductCategory } from "src/common/models/productCategory"
import { getInputRequiredRule } from "src/common/util"
import { Col24 } from "src/components/Columns"
import { FormActionButtons } from "src/components/FormActionButtons"
import { UPSERT_PRODUCT_CATEGORY_API } from "src/features/ProductSettings/state/actionTypes"
import { ProductSettingsError } from "src/features/ProductSettings/state/types"
import { RootState } from "src/state/reducer"

interface ReduxProps {
    isUpsertingProductCategory: boolean
    error?: ProductSettingsError
}

interface Props {
    initialValues?: ProductCategory
    onCancel: () => void
    onFinish: (productCategory: Partial<ProductCategory>) => void
}

export const ProductCategoryForm = (props: Props) => {

    const descriptions = SystemDescriptions.PAGES.PRODUCT.PRODUCT_PROPERTIES.CATEGORIES.UPSERT
    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isUpsertingProductCategory: serviceLoading(state, [UPSERT_PRODUCT_CATEGORY_API]),
        error: state.productSettings.error,
    }))

    const [form] = Form.useForm();

    const handleCancelClick = () => {
        props.onCancel()
    }

    const getButtonStatus = (): boolean => {
        return props.initialValues
            ? false
            : !form.isFieldsTouched(true) ||
            form.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0 || reduxProps.isUpsertingProductCategory
    }

    const renderButtons = () => (
        <FormActionButtons
            cancelText={descriptions.CANCEL}
            actionText={descriptions.ACCEPT}
            actionDisabled={getButtonStatus}
            onCancel={handleCancelClick}
            actionLoading={reduxProps.isUpsertingProductCategory}
        />
    )

    const onFinish = (formValues) => {
        const productBrand: Partial<ProductBrand> = {
            name: formValues.name,
            description: formValues.description,
        }

        props.onFinish(productBrand)
    }

    const renderForm = () => {
        return <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            autoComplete={'none'}
            onFinish={onFinish}
        >
            <Row gutter={[16, 8]}>
                <Col24>
                    <Form.Item
                        label={descriptions.FORM.NAME.LABEL}
                        name={"name"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.NAME.LABEL)
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.NAME.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col24>
                <Col24>
                    <Form.Item
                        label={descriptions.FORM.DESCRIPTION.LABEL}
                        name={"description"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.DESCRIPTION.LABEL)
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.DESCRIPTION.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col24>
            </Row>
            {
                reduxProps.error &&
                <Alert
                    message={"ERROR"}
                    showIcon
                    description={reduxProps.error?.detail.message ?? commonDescriptions.FORM.UNKNOWN_ERROR}
                    type="error"
                    style={{
                        marginBottom: 12,
                    }}
                />
            }
            {renderButtons()}
        </Form>
    }

    return (
        renderForm()
    )
}
