import { Languages } from "src/common/descriptions/types";
import { CreateProductDescriptions } from "./types";

const DescriptionsEnUs: CreateProductDescriptions = {
    EDIT_TITLE: "Edit Product",
    TITLE: "Create Product",
    TAB_DATA: "PRODUCT DATA",
    TAB_PRESENTATIONS: "PRESENTATIONS",
    SAVE_BUTTON: "Save",
    CANCEL_BUTTON: "Cancel",
    PRODUCT_TYPE_GOOD: "Good",
    PRODUCT_TYPE_SERVICE: "Service",
    CONFIRM_MODAL: {
        TITLE: 'Save product?',
        DESCRIPTION: "Operation will not be reverted.",
        ACTION_BUTTON: "Save",
        CANCEL_BUTTON: "Go Back",
    },
    CANCEL_MODAL: {
        TITLE: "Cancel Product input?",
        DESCRIPTION: "All data will be lost.",
        ACTION_BUTTON: "Yes, Cancel",
        CANCEL_BUTTON: "Keep working",
    },
    SUCCESS_MODAL: {
        TITLE: "Success",
        PRIMARY_LABEL: "Accept",
        SECONDARY_LABEL: "Accept",
        DESCRIPTION: "Product Created",
    },
    SUCCESS_SECONDARY_MODAL: {
        TITLE: "Success",
        PRIMARY_LABEL: "Accept",
        SECONDARY_LABEL: "Accept",
        DESCRIPTION: "Product Updated",
    },
    ERRORS: {
        REQUIRED_FIELD: (fieldName: string) => `${fieldName} is required.`,
        SAVE_ACTION: "",
        UNKNOWN: "An error ocurred while processing request, please contact support."
    },
    FORM: {
        DESCRIPTION: {
            LABEL: "Description",
            PLACEHOLDER: "Input description",
        },
        BRAND: {
            LABEL: "Brand",
            PLACEHOLDER: "Select brand",
        },
        SUBBRAND: {
            LABEL: "SubBrand",
            PLACEHOLDER: "Select SubBrand",
        },
        CATEGORY: {
            LABEL: "Category",
            PLACEHOLDER: "Select Category",
        },
        MEASSURE: {
            LABEL: "Meassure",
            PLACEHOLDER: "Select Meassure",
        },
        SUIPPLIER: {
            LABEL: "Supplier",
            PLACEHOLDER: "Select Supplier",
        },
        STATUS: {
            LABEL: "Status",
            PLACEHOLDER: "Select Status",
        },
        VOLUME: {
            LABEL: "Volume",
            PLACEHOLDER: "Input Volume",
        },
        PRODUCT_TYPE: {
            LABEL: "Product Type",
            PLACEHOLDER: "Select Product Type",
        },
    },
    CREATE_PRESENTATION: {
        TITLE: "Create Presentation",
        FORM: {
            MEASSURE: {
                LABEL: "Presentation",
                PLACEHOLDER: "Select presentation",
            },
            QTY: {
                LABEL: "Qty",
                PLACEHOLDER: "Input quantity",
            },
            UNIT_PRICE: {
                LABEL: "Unit Price",
                PLACEHOLDER: "Input unit price",
            },
            SALE_PRICE: {
                LABEL: "Sale Price",
                PLACEHOLDER: "Input sale price",
            },
            MIN_SALE_PRICE: {
                LABEL: "Min Sale Price",
                PLACEHOLDER: "Input min sale price",
            },
            BARCODE: {
                LABEL: "Barcode",
                PLACEHOLDER: "Input Barcode",
            },
        },
    },
    PRESENTATIONS: {
        TITLE: "Product Presentations",
        COSTE: {
            LABEL: "Coste",
            PLACEHOLDER: "Input coste",
        },
        TABLE: {
            ACTION_BUTTON: "Create price",
            SEARCH_PLACEHOLDER: "Search",
            LOADING_DATA: "Loading data",
        },
        COLUMNS: {
            PRESENTATION: "Presentation",
            QTY: "Qty",
            UNIT_PRICE: "Unit Price",
            SALE_PRICE: "Sale Price",
            ACTIONS: "Actions",
            BARCODE: "Barcode",
            MIN_SALE_PRICE: "Min Sale Price",
        },
        ALERTS: {
            MINIMAL_UNIT_MISSING: "Product must have a minimal unit presentation (Qty: 1)",
            NO_PRESENTATIONS: "Create at least one presentation",
            MINIMAL_UNIT_UNIQUE: "Product must have only one minimal unit",
        },
    },
    MAIN_ACTION_TITLE: "Creating Product",
    MAIN_ACTION_DESCRIPTION: "Please wait...",
}

const DescriptionsEsUs: CreateProductDescriptions = {
    EDIT_TITLE: "Editar Producto",
    TITLE: "Crear Producto",
    TAB_DATA: "INFORMACION PRODUCTO",
    TAB_PRESENTATIONS: "PRESENTACIONES",
    SAVE_BUTTON: "Guardar",
    CANCEL_BUTTON: "Cancelar",
    PRODUCT_TYPE_GOOD: "Bien",
    PRODUCT_TYPE_SERVICE: "Servicio",
    CONFIRM_MODAL: {
        TITLE: 'Guardar Producto con los datos ingresados?',
        DESCRIPTION: "La operación no podrá ser revertida.",
        ACTION_BUTTON: "Guardar",
        CANCEL_BUTTON: "Regresar",
    },
    CANCEL_MODAL: {
        TITLE: "Cancelar ingreso de Producto?",
        DESCRIPTION: "La información ingresada sera borrada.",
        ACTION_BUTTON: "Si, Cancelar",
        CANCEL_BUTTON: "Seguir ingresando",
    },
    SUCCESS_MODAL: {
        TITLE: "Exito",
        PRIMARY_LABEL: "Aceptar",
        SECONDARY_LABEL: "Aceptar",
        DESCRIPTION: "Producto creado",
    },
    SUCCESS_SECONDARY_MODAL: {
        TITLE: "Exito",
        PRIMARY_LABEL: "Aceptar",
        SECONDARY_LABEL: "Aceptar",
        DESCRIPTION: "Proucto Actualizado",
    },
    ERRORS: {
        REQUIRED_FIELD: (fieldName: string) => `${fieldName} es requerido.`,
        SAVE_ACTION: "",
        UNKNOWN: "Ha ocurrido un error al procesar la operación, por favor contacta a soporte."
    },
    FORM: {
        DESCRIPTION: {
            LABEL: "Descripción",
            PLACEHOLDER: "Ingrse descripción",
        },
        BRAND: {
            LABEL: "Marca",
            PLACEHOLDER: "Seleccione marca",
        },
        SUBBRAND: {
            LABEL: "SubMarca",
            PLACEHOLDER: "Seleccione SubMarca",
        },
        CATEGORY: {
            LABEL: "Categoría",
            PLACEHOLDER: "Seleccione categoría",
        },
        MEASSURE: {
            LABEL: "Medida",
            PLACEHOLDER: "Seleccione medida",
        },
        SUIPPLIER: {
            LABEL: "Proveedor",
            PLACEHOLDER: "Seleccione proveedor",
        },
        STATUS: {
            LABEL: "Estado",
            PLACEHOLDER: "Seleccione estado",
        },
        VOLUME: {
            LABEL: "Volumen",
            PLACEHOLDER: "Ingrese volumen",
        },
        PRODUCT_TYPE: {
            LABEL: "Tipo de Producto",
            PLACEHOLDER: "Seleccione tipo de producto",
        },
    },
    CREATE_PRESENTATION: {
        TITLE: "Crear Presentación",
        FORM: {
            MEASSURE: {
                LABEL: "Presentación",
                PLACEHOLDER: "Seleccione presentación",
            },
            QTY: {
                LABEL: "Cant",
                PLACEHOLDER: "Ingrese cantidad",
            },
            UNIT_PRICE: {
                LABEL: "Precio Unitario",
                PLACEHOLDER: "Ingrese precio unitario",
            },
            SALE_PRICE: {
                LABEL: "Precio de venta",
                PLACEHOLDER: "Ingrese precio de venta",
            },
            MIN_SALE_PRICE: {
                LABEL: "Precio mínimo",
                PLACEHOLDER: "Ingrese precio mínimo",
            },
            BARCODE: {
                LABEL: "Cod Barras",
                PLACEHOLDER: "Ingrese código de barras",
            },
        },
    },
    PRESENTATIONS: {
        TITLE: "Presentaciones de Producto",
        COSTE: {
            LABEL: "Costo",
            PLACEHOLDER: "Ingrese costo",
        },
        TABLE: {
            ACTION_BUTTON: "Create presentacion",
            SEARCH_PLACEHOLDER: "Buscar",
            LOADING_DATA: "Cargando datos",
        },
        COLUMNS: {
            PRESENTATION: "Presentación",
            QTY: "Cant",
            UNIT_PRICE: "Precio Unitario",
            SALE_PRICE: "Precio Venta",
            ACTIONS: "Acciones",
            BARCODE: "Cod Barras",
            MIN_SALE_PRICE: "Precio Mínimo",
        },
        ALERTS: {
            MINIMAL_UNIT_MISSING: "El producto debe tener una presentación con la unidad mínima",
            NO_PRESENTATIONS: "Aún no se han creado presentaciones",
            MINIMAL_UNIT_UNIQUE: "El producto solo debe tener una unidad mínima",
        },
    },
    MAIN_ACTION_TITLE: "Creando Tipo de Pago",
    MAIN_ACTION_DESCRIPTION: "Por favor espere...",
}

export const createProductDictionary = {}
createProductDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
createProductDictionary[Languages.SPANISH_US] = DescriptionsEsUs