import { call, put, takeLatest, } from 'redux-saga/effects';
import { Urls } from 'src/common/urls';
import * as Actions from './action';
import * as ActionTypes from './actionTypes';
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider';
import { ProductSettingsType } from './types';

const buildSettingsQuery = (settingsTypes?: ProductSettingsType[]): string => {
    return settingsTypes && settingsTypes?.length > 0
        ? `?types=${settingsTypes.join(',')}`
        : ''
}

function* getProductSettingsApi(action: Actions.GetProductSettingsAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.PRODUCT_SETTINGS.GET_SETTINGS(buildSettingsQuery(action.settingsTypes)))
        yield put(Actions.getProductSettingsSuccess(response.data))
    } catch (err) {
        yield put(Actions.getProductSettingsError(err));
    }
}

function* upsertProductBrandApi(action: Actions.UpsertProductBrandAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.PRODUCT_SETTINGS.UPSERT_PRODUCT_BRAND, action.brand)
        yield put(Actions.upsertProductBrandSuccess(response.data))
    } catch (err) {
        yield put(Actions.upsertProductBrandError(err));
    }
}

function* upsertProductSubBrandApi(action: Actions.UpsertProductSubBrandAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.PRODUCT_SETTINGS.UPSERT_PRODUCT_SUBBRAND, action.subBrand)
        yield put(Actions.upsertProductSubBrandSuccess(response.data))
    } catch (err) {
        yield put(Actions.upsertProductSubBrandError(err));
    }
}

function* upsertProductCategoryApi(action: Actions.UpsertProductCategoryAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.PRODUCT_SETTINGS.UPSERT_PRODUCT_CATEGORY, action.category)
        yield put(Actions.upsertProductCategorySuccess(response.data))
    } catch (err) {
        yield put(Actions.upsertProductCategoryError(err));
    }
}

export default function* productSettingsSagas() {
    yield takeLatest(ActionTypes.GET_PRODUCT_SETTINGS_API, getProductSettingsApi)
    yield takeLatest(ActionTypes.UPSERT_PRODUCT_BRAND_API, upsertProductBrandApi)
    yield takeLatest(ActionTypes.UPSERT_PRODUCT_SUBBRAND_API, upsertProductSubBrandApi)
    yield takeLatest(ActionTypes.UPSERT_PRODUCT_CATEGORY_API, upsertProductCategoryApi)
}