import { useDispatch, useSelector } from "react-redux"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { Supplier } from "src/common/models/supplier"
import { SearchableTable } from "src/components/SearchableTable"
import { RootState } from "src/state/reducer"
import { GET_SUPPLIERS_API } from "../state/actionTypes"
import { getSuppliersApi } from "../state/action"
import { useEffect } from "react"
import { TABLE_COLUMNS } from "./config"
import { SystemDescriptions } from "src/common/descriptions"
import { Urls } from "src/common/urls"
import { useNavigate } from "react-router"

interface ReduxProps {
  suppliers: Supplier[]
  isLoadingSuppliers: boolean
}

export const SuppliersRecords = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const descriptions = SystemDescriptions.PAGES.SUPPLIER.SUPPLIERS

  const reduxProps: ReduxProps = useSelector((state: RootState) => ({
    suppliers: state.supplier.suppliers ?? [],
    isLoadingSuppliers: serviceLoading(state, [GET_SUPPLIERS_API]),
  }))

  useEffect(() => {
    dispatch(getSuppliersApi())
  }, [])

  const tableColumns = [
    ...TABLE_COLUMNS,
  ]

  const handleTableActionClick = () => {
    navigate(Urls.FRONTEND.SUPPLIER.CREATE)
  }

  return (
    <SearchableTable
      showActionButton
      actionButtonLabel={descriptions.TABLE.ACTION_BUTTON}
      actionButtonOnClick={handleTableActionClick}
      items={reduxProps.suppliers}
      tableColumns={tableColumns}
      searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
      showSearch
      showLoader={reduxProps.isLoadingSuppliers}
    />
  )
}
