import { Action } from 'redux'
import * as Actions from './actionTypes'
import { GetStockRegularizationRecordsFilters, StockRegularization } from 'src/common/models/stockRegularization'
import { buildErrorAction, ErrorAction } from 'src/common/apiLoader/state/actions'

export interface GetRegulariationRecordsAction extends Action {
    filters?: GetStockRegularizationRecordsFilters
}
export interface GetRegulariationRecordsSuccessAction extends Action {
    regularizations: StockRegularization[]
}
export const getRegularizationRecordsApi = (filters?: GetStockRegularizationRecordsFilters): GetRegulariationRecordsAction => ({
    type: Actions.GET_REGULARIZATION_RECORDS_API,
    filters,
})
export const getRegularizationRecordsSuccess = (regularizations: StockRegularization[]): GetRegulariationRecordsSuccessAction => ({
    type: Actions.GET_REGULARIZATION_RECORDS_API_SUCCESS,
    regularizations,
})
export const getRegularizationRecordsError = (error?: any): ErrorAction => buildErrorAction(
    Actions.GET_REGULARIZATION_RECORDS_API_ERROR,
    error,
)

export interface CreateRegulariationAction extends Action {
    regularizations: StockRegularization[]
}
export interface CreateRegulariationSuccessAction extends Action {
    regularizations: StockRegularization[]
}
export const createRegularizationApi = (regularizations: StockRegularization[]): CreateRegulariationAction => ({
    type: Actions.CREATE_REGULARIZATION_API,
    regularizations,
})
export const createRegularizationSuccess = (regularizations: StockRegularization[]): CreateRegulariationSuccessAction => ({
    type: Actions.CREATE_REGULARIZATION_API_SUCCESS,
    regularizations,
})
export const createRegularizationError = (error?: any): ErrorAction => buildErrorAction(
    Actions.CREATE_REGULARIZATION_API_ERROR,
    error,
)