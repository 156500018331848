import { SystemDescriptions } from "src/common/descriptions";
import { SidebarDescriptions } from "src/common/descriptions/components/Sidebar/types";
import { UserRoles } from "src/common/models/user";
import { Urls } from "src/common/urls";

interface MenuOptionChildren {
    path: string
    id: string
    displayName: string
}

export interface MenuOption {
    id: string;
    displayName: string;
    path: string;
    icon: string;
    iconSelected: string;
    children: MenuOptionChildren[]
    roles?: UserRoles[]
}

export interface MenuSection {
    id: string;
    displayName: string;
    icon: string;
    iconSelected: string;
    items: MenuOption[]
    path?: string
    roles: UserRoles[]
}

const MenuDescriptions: SidebarDescriptions = SystemDescriptions.COMPONENTS.SIDEBAR

export const MenuSections: MenuSection[] = [
    {
        id: '0',
        displayName: MenuDescriptions.HOME.TITLE,
        icon: 'fas fa-home',
        iconSelected: '',
        roles: [UserRoles.ALL],
        items: [],
        path: Urls.FRONTEND.HOME,
    },
    {
        id: 'POS',
        displayName: MenuDescriptions.POS.TITLE,
        icon: 'fas fa-window-maximize',
        iconSelected: '',
        roles: [UserRoles.ALL],
        items: [
            {
                path: Urls.FRONTEND.SALE.CREATE,
                id: Urls.FRONTEND.SALE.CREATE,
                displayName: MenuDescriptions.POS.ITEMS.CREATE_SALE.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.SALE.RECORDS,
                id: Urls.FRONTEND.SALE.RECORDS,
                displayName: MenuDescriptions.POS.ITEMS.SALE_RECORDS.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.PRODUCT.STOCK,
                id: Urls.FRONTEND.PRODUCT.STOCK,
                displayName: MenuDescriptions.POS.ITEMS.PRODUCTS_STOCK.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.PRODUCT.PRODUCTS,
                id: Urls.FRONTEND.PRODUCT.PRODUCTS,
                displayName: MenuDescriptions.ADMINISTRATION.ITEMS.PRODUCTS.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.SALE.RECEIVABLES,
                id: Urls.FRONTEND.SALE.RECEIVABLES,
                displayName: MenuDescriptions.ADMINISTRATION.ITEMS.ACCOUNT_RECEIVABLES.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.SALE.PAYMENT_RECORDS,
                id: Urls.FRONTEND.SALE.PAYMENT_RECORDS,
                displayName: MenuDescriptions.POS.ITEMS.CUSTOMER_PAYMENT_RECORDS.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.CUSTOMER.CUSTOMERS,
                id: Urls.FRONTEND.CUSTOMER.CUSTOMERS,
                displayName: MenuDescriptions.POS.ITEMS.CUSTOMERS.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
        ],
    },
    {
        id: 'A',
        displayName: MenuDescriptions.ADMINISTRATION.TITLE,
        icon: 'fas fa-window-maximize',
        iconSelected: '',
        roles: [UserRoles.ADMIN],
        items: [
            {
                path: Urls.FRONTEND.PURCHASE.CREATE,
                id: Urls.FRONTEND.PURCHASE.CREATE,
                displayName: MenuDescriptions.ADMINISTRATION.ITEMS.CREATE_PURCHASE.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.PURCHASE.RECORDS,
                id: Urls.FRONTEND.PURCHASE.RECORDS,
                displayName: MenuDescriptions.ADMINISTRATION.ITEMS.PURCHASE_RECORDS.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.PRODUCT.CREATE,
                id: Urls.FRONTEND.PRODUCT.CREATE,
                displayName: MenuDescriptions.ADMINISTRATION.ITEMS.CREATE.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.PURCHASE.PAYABLES,
                id: Urls.FRONTEND.PURCHASE.PAYABLES,
                displayName: MenuDescriptions.ADMINISTRATION.ITEMS.ACCOUNT_PAYABLES.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.SUPPLIER.RECORDS,
                id: Urls.FRONTEND.SUPPLIER.RECORDS,
                displayName: MenuDescriptions.ADMINISTRATION.ITEMS.SUPPLIERS.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.STOCK_REGULARIZATION.RECORDS,
                id: Urls.FRONTEND.STOCK_REGULARIZATION.RECORDS,
                displayName: MenuDescriptions.ADMINISTRATION.ITEMS.STOCK_REGULARIZATIONS.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
        ],
    },
    {
        id: 'ROUTING',
        displayName: MenuDescriptions.ROUTING.TITLE,
        icon: 'fas fa-window-maximize',
        iconSelected: '',
        roles: [UserRoles.ADMIN],
        items: [
            {
                path: Urls.FRONTEND.ROUTING.CREATE_SALE,
                id: Urls.FRONTEND.ROUTING.CREATE_SALE,
                displayName: MenuDescriptions.ROUTING.ITEMS.CREATE_SALE.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
        ],
    },
    {
        id: 'REPORT',
        displayName: MenuDescriptions.REPORTS.TITLE,
        icon: 'fas fa-window-maximize',
        iconSelected: '',
        roles: [UserRoles.ADMIN],
        items: [
            {
                path: Urls.FRONTEND.REPORTS.KARDEX,
                id: Urls.FRONTEND.REPORTS.KARDEX,
                displayName: MenuDescriptions.REPORTS.ITEMS.KARDEX.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.REPORTS.UNITS_SOLD,
                id: Urls.FRONTEND.REPORTS.UNITS_SOLD,
                displayName: MenuDescriptions.REPORTS.ITEMS.UNITS_SOLD.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.REPORTS.SALES_REPORT,
                id: Urls.FRONTEND.REPORTS.SALES_REPORT,
                displayName: MenuDescriptions.REPORTS.ITEMS.SALES.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.REPORTS.UNITS_SOLD_UTILITY,
                id: Urls.FRONTEND.REPORTS.UNITS_SOLD_UTILITY,
                displayName: MenuDescriptions.REPORTS.ITEMS.UNITS_SOLD_UTILITY.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.REPORTS.STOCK_VALUE,
                id: Urls.FRONTEND.REPORTS.STOCK_VALUE,
                displayName: MenuDescriptions.REPORTS.ITEMS.STOCK_VALUE.DISPLAY_NAME,
                icon: "fas fa-window-restore",
                iconSelected: "",
                children: [],
            },
        ],
    },
    {
        id: 'MAINTAINANCE',
        displayName: MenuDescriptions.MAINTENANCE.TITLE,
        icon: 'fas fa-cog',
        iconSelected: '',
        roles: [UserRoles.ADMIN],
        items: [
            {
                path: Urls.FRONTEND.SETTINGS,
                id: Urls.FRONTEND.SETTINGS,
                displayName: MenuDescriptions.MAINTENANCE.ITEMS.SETTINGS.DISPLAY_NAME,
                icon: "fas fa-cog",
                iconSelected: "",
                children: [],
            },
            {
                path: Urls.FRONTEND.PRODUCT.PRODUCTS_SETTINGS,
                id: Urls.FRONTEND.PRODUCT.PRODUCTS_SETTINGS,
                displayName: MenuDescriptions.MAINTENANCE.ITEMS.PRODUCTS_SETTINGS.DISPLAY_NAME,
                icon: "fas fa-cog",
                iconSelected: "",
                children: [],
            },
        ],
    },
]