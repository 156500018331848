import { Action } from 'redux'
import * as Actions from './actionTypes'
import { ProductSettings, ProductSettingsType } from './types'
import { ProductBrand } from 'src/common/models/productBrand'
import { ProductSubBrand } from 'src/common/models/productSubBrand'
import { ProductCategory } from 'src/common/models/productCategory'

export interface GetProductSettingsAction extends Action {
    settingsTypes?: ProductSettingsType[]
}
export interface GetProductSettingsSuccessAction extends Action {
    productSettings: ProductSettings
}
export interface ErrorAction extends Action {
    error?
}
export const getProductSettings = (settingsTypes?: ProductSettingsType[]): GetProductSettingsAction => ({
    type: Actions.GET_PRODUCT_SETTINGS_API,
    settingsTypes,
})
export const getProductSettingsSuccess = (productSettings: ProductSettings): GetProductSettingsSuccessAction => ({
    type: Actions.GET_PRODUCT_SETTINGS_SUCCESS,
    productSettings,
})
export const getProductSettingsError = (error?): ErrorAction => ({
    type: Actions.GET_PRODUCT_SETTINGS_ERROR,
    error,
})

export interface UpsertProductBrandAction extends Action {
    brand: ProductBrand
}
export interface UpsertProductBrandSuccessAction extends Action {
    brand: ProductBrand
}
export const upsertProductBrandApi = (brand: ProductBrand): UpsertProductBrandAction => ({
    type: Actions.UPSERT_PRODUCT_BRAND_API,
    brand,
})
export const upsertProductBrandSuccess = (brand: ProductBrand): UpsertProductBrandSuccessAction => ({
    type: Actions.UPSERT_PRODUCT_BRAND_SUCCESS,
    brand,
})
export const upsertProductBrandError = (error?): ErrorAction => ({
    type: Actions.UPSERT_PRODUCT_BRAND_ERROR,
    error,
})

export interface UpsertProductSubBrandAction extends Action {
    subBrand: ProductSubBrand
}
export interface UpsertProductSubBrandSuccessAction extends Action {
    subBrand: ProductSubBrand
}
export const upsertProductSubBrandApi = (subBrand: ProductSubBrand): UpsertProductSubBrandAction => ({
    type: Actions.UPSERT_PRODUCT_SUBBRAND_API,
    subBrand,
})
export const upsertProductSubBrandSuccess = (subBrand: ProductSubBrand): UpsertProductSubBrandSuccessAction => ({
    type: Actions.UPSERT_PRODUCT_SUBBRAND_SUCCESS,
    subBrand,
})
export const upsertProductSubBrandError = (error?): ErrorAction => ({
    type: Actions.UPSERT_PRODUCT_SUBBRAND_ERROR,
    error,
})

export interface UpsertProductCategoryAction extends Action {
    category: ProductCategory
}
export interface UpsertProductCategorySuccessAction extends Action {
    category: ProductCategory
}
export const upsertProductCategoryApi = (category: ProductCategory): UpsertProductCategoryAction => ({
    type: Actions.UPSERT_PRODUCT_CATEGORY_API,
    category,
})
export const upsertProductCategorySuccess = (category: ProductCategory): UpsertProductCategorySuccessAction => ({
    type: Actions.UPSERT_PRODUCT_CATEGORY_SUCCESS,
    category,
})
export const upsertProductCategoryError = (error?): ErrorAction => ({
    type: Actions.UPSERT_PRODUCT_CATEGORY_ERROR,
    error,
})