import { getSessionData } from "src/session/sessionStore"
import { getCommonPagesDescriptions } from "./Common"
import { getAddProductDescriptions } from "./components/AddProduct"
import { getBreadcrumbDescriptions } from "./components/breadcrumb"
import { getDashboardDescriptions } from "./components/Dashboard"
import { getFooterDescriptions } from "./components/footer"
import { getNavbarDescriptions } from "./components/navbar"
import { getPaymentModalDescriptions } from "./components/PaymentModal"
import { getProductTransactionsDescriptions } from "./components/ProductTransactionModal"
import { getSearchableTableDescriptions } from "./components/searchableTable"
import { getSidebarDescriptions } from "./components/Sidebar"
import { getProductsDescriptions } from "./Pages/Product"
import { getPurchaseDescriptions } from "./Pages/Purchase"
import { getReportsDescriptions } from "./Pages/Reports"
import { getSaleDescriptions } from "./Pages/Sale"
import { getSecurityDescriptions } from "./Pages/security"
import { getSettingsDescriptions } from "./Pages/Settings"
import { DefaultSettings, Descriptions } from "./types"
import { getLoaderDescriptions } from "./components/Loader"
import { getCustomerDescriptions } from "./Pages/Customer"
import { getSuppliersDescriptions } from "./Pages/Supplier"
import { getRegularizationDescriptions } from "./Pages/Regularization"

const systemLang = getSessionData().language ?? DefaultSettings.SYSTEM_LANG

export const SystemDescriptions: Descriptions = {
    PAGES: {
        PRODUCT: getProductsDescriptions(systemLang),
        SECURITY: getSecurityDescriptions(systemLang),
        COMMON: getCommonPagesDescriptions(systemLang),
        PURCHASE: getPurchaseDescriptions(systemLang),
        SALE: getSaleDescriptions(systemLang),
        REPORTS: getReportsDescriptions(systemLang),
        SETTINGS: getSettingsDescriptions(systemLang),
        CUSTOMER: getCustomerDescriptions(systemLang),
        SUPPLIER: getSuppliersDescriptions(systemLang),
        REGULARIZATION: getRegularizationDescriptions(systemLang),
    },
    COMPONENTS: {
        BREADCRUMB: getBreadcrumbDescriptions(systemLang),
        FOOTER: getFooterDescriptions(systemLang),
        NAVBAR: getNavbarDescriptions(systemLang),
        SIDEBAR: getSidebarDescriptions(systemLang),
        SEARCHABLE_TABLE: getSearchableTableDescriptions(systemLang),
        ADD_PRODUCT: getAddProductDescriptions(systemLang),
        PRODUCT_TRANSACTION: getProductTransactionsDescriptions(systemLang),
        DASHBOARD: getDashboardDescriptions(systemLang),
        MODAL_PAYMENT: getPaymentModalDescriptions(systemLang),
        LOADER: getLoaderDescriptions(systemLang),
    }
}