import { ProductBrand } from "src/common/models/productBrand"
import { ProductCategory } from "src/common/models/productCategory"
import { ProductMeassure } from "src/common/models/productMeassure"
import { ProductSubBrand } from "src/common/models/productSubBrand"
import { ApiError } from "src/features/Security/networking/types"

export enum ProductSettingsErrorOrigin {
    GET_PRODUCT_SETTINGS = 'GET_PRODUCT_SETTINGS',
    UPSERT_PRODUCT_BRAND = 'UPSERT_PRODUCT_BRAND',
    UPSERT_PRODUCT_SUBBRAND = 'UPSERT_PRODUCT_SUBBRAND',
    UPSERT_PRODUCT_CATEGORY = 'UPSERT_PRODUCT_CATEGORY',
}

export interface ProductSettingsError {
    type: ProductSettingsErrorOrigin,
    detail: ApiError
}

export enum ProductSettingsType {
    BRAND = 'BRAND',
    SUBBRAND = 'SUBBRAND',
    CATEGORY = 'CATEGORY',
    MEASSURE = 'MEASSURE',
    ALL = 'ALL',
}

export interface ProductSettings {
    brands?: ProductBrand[]
    subBrands?: ProductSubBrand[]
    categories?: ProductCategory[]
    meassures?: ProductMeassure[]
}