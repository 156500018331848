import { Action } from 'redux'
import * as Actions from './actionTypes'
import { Supplier } from 'src/common/models/supplier'

export interface getSuppliersAction extends Action {
}

export interface getSuppliersSuccessAction extends Action {
    suppliers: Supplier[]
}

export interface ErrorAction extends Action {
    error?
}

export const getSuppliersApi = (): getSuppliersAction => ({
    type: Actions.GET_SUPPLIERS_API
})

export const getSuppliersApiSuccess = (suppliers: Supplier[]): getSuppliersSuccessAction => ({
    type: Actions.GET_SUPPLIERS_API_SUCCESS,
    suppliers,
})

export const getSuppliersApiError = (error?): ErrorAction => ({
    type: Actions.GET_SUPPLIERS_API_ERROR,
    error,
})

export interface UpsertSupplierAction extends Action {
    supplier: Supplier
}
export interface UpsertSupplierSuccessAction extends Action {
    supplier: Supplier
}
export const upsertSupplierApi = (supplier: Supplier): UpsertSupplierAction => ({
    type: Actions.UPSERT_SUPPLIER_API,
    supplier,
})
export const upsertSupplierApiSuccess = (supplier: Supplier): UpsertSupplierSuccessAction => ({
    type: Actions.UPSERT_SUPPLIER_API_SUCCESS,
    supplier,
})
export const upsertSuppliersApiError = (error?): ErrorAction => ({
    type: Actions.UPSERT_SUPPLIER_API_ERROR,
    error,
})