import { Languages } from "../../types";
import { createProductDictionary } from "./CreateProduct";
import { productPropertiesDictionary } from "./ProductSettings";
import { productsDictionary } from "./Products";
import { warehouseStockDictionary } from "./WarehouseStock";
import { ProductPagesDescriptions } from "./types";

export const getProductsDescriptions = (systemLang: Languages): ProductPagesDescriptions => ({
    CREATE_PRODUCT: createProductDictionary[systemLang],
    PRODUCTS: productsDictionary[systemLang],
    WAREHOUSE_STOCK: warehouseStockDictionary[systemLang],
    PRODUCT_PROPERTIES: productPropertiesDictionary[systemLang],
})