import { Languages } from "src/common/descriptions/types";
import { ProductPropertiesDescriptions } from "./types";

const DescriptionsEnUs: ProductPropertiesDescriptions = {
    TITLE: "Product Properties",
    BRANDS: {
        TITLE: "Brands",
        RECORDS: {
            TABLE: {
                ACTION_BUTTON: "Create Brand",
                SEARCH_PLACEHOLDER: "Search",
                LOADING_DATA: "Loading data...",
            },
            TABLE_COLUMNS: {
                ID: "No",
                NAME: "Name",
                DESCRIPTION: "Description",
            },
        },
        UPSERT: {
            TITLE: "Create Product Brand",
            EDIT_TITLE: "Update Product Brand",
            ACCEPT: "Accept",
            CANCEL: "Cancel",
            FORM: {
                NAME: {
                    LABEL: "Name",
                    PLACEHOLDER: "Input name",
                },
                DESCRIPTION: {
                    LABEL: "Description",
                    PLACEHOLDER: "Input description",
                },
            },
        },
    },
    SUBBRANDS: {
        TITLE: "Subbrands",
        RECORDS: {
            TABLE: {
                ACTION_BUTTON: "Create Subbrand",
                SEARCH_PLACEHOLDER: "Search",
                LOADING_DATA: "Loading data...",
            },
            TABLE_COLUMNS: {
                ID: "No",
                NAME: "Name",
                DESCRIPTION: "Description",
                BRAND: "Brand",
            },
        },
        UPSERT: {
            TITLE: "Create Sub Brand",
            EDIT_TITLE: "Edit SubBrand",
            ACCEPT: "Accept",
            CANCEL: "Cancel",
            FORM: {
                NAME: {
                    LABEL: "Name",
                    PLACEHOLDER: "Input name",
                },
                DESCRIPTION: {
                    LABEL: "Description",
                    PLACEHOLDER: "Input description",
                },
                BRAND: {
                    LABEL: "Brand",
                    PLACEHOLDER: "Select Brand",
                },
            },
        },
    },
    CATEGORIES: {
        TITLE: "Categories",
        RECORDS: {
            TABLE: {
                ACTION_BUTTON: "Create Category",
                SEARCH_PLACEHOLDER: "Search",
                LOADING_DATA: "Loading data...",
            },
            TABLE_COLUMNS: {
                ID: "No",
                NAME: "Name",
                DESCRIPTION: "Description",
            },
        },
        UPSERT: {
            TITLE: "Create Category",
            EDIT_TITLE: "Edit Category",
            ACCEPT: "Accept",
            CANCEL: "Cancel",
            FORM: {
                NAME: {
                    LABEL: "Name",
                    PLACEHOLDER: "Input name",
                },
                DESCRIPTION: {
                    LABEL: "Description",
                    PLACEHOLDER: "Input description",
                },
            }
        },
    },
}

const DescriptionsEsUs: ProductPropertiesDescriptions = {
    TITLE: "Propiedades de Producto",
    BRANDS: {
        TITLE: "Marcas",
        RECORDS: {
            TABLE: {
                ACTION_BUTTON: "Crear Marca",
                SEARCH_PLACEHOLDER: "Buscar",
                LOADING_DATA: "Cargando datos...",
            },
            TABLE_COLUMNS: {
                ID: "No",
                NAME: "Nombre",
                DESCRIPTION: "Descripcion",
            },
        },
        UPSERT: {
            TITLE: "Crear Marca de Producto",
            EDIT_TITLE: "Editar Marca de Producto",
            ACCEPT: "Aceptar",
            CANCEL: "Cancelar",
            FORM: {
                NAME: {
                    LABEL: "Nombre",
                    PLACEHOLDER: "Ingresa nombre",
                },
                DESCRIPTION: {
                    LABEL: "Descripción",
                    PLACEHOLDER: "Ingresa descripción",
                }
            }
        },
    },
    SUBBRANDS: {
        TITLE: "Sub-Marca",
        RECORDS: {
            TABLE: {
                ACTION_BUTTON: "Crear Sub-Marca",
                SEARCH_PLACEHOLDER: "Buscar",
                LOADING_DATA: "Cargando datos...",
            },
            TABLE_COLUMNS: {
                ID: "No",
                NAME: "Nombre",
                DESCRIPTION: "Descripcion",
                BRAND: "Marca",
            },
        },
        UPSERT: {
            TITLE: "Crear Sub Marca",
            EDIT_TITLE: "Editar Sub Marca",
            ACCEPT: "Aceptar",
            CANCEL: "Cancelar",
            FORM: {
                NAME: {
                    LABEL: "Nombre",
                    PLACEHOLDER: "Ingrese Nombre",
                },
                DESCRIPTION: {
                    LABEL: "Descripción",
                    PLACEHOLDER: "Ingrese Descripción",
                },
                BRAND: {
                    LABEL: "Marca",
                    PLACEHOLDER: "Seleccione Marca",
                },
            },
        },
    },
    CATEGORIES: {
        TITLE: "Categorías",
        RECORDS: {
            TABLE: {
                ACTION_BUTTON: "Crear Categoría",
                SEARCH_PLACEHOLDER: "Buscar",
                LOADING_DATA: "Cargando datos...",
            },
            TABLE_COLUMNS: {
                ID: "No",
                NAME: "Nombre",
                DESCRIPTION: "Descripcion",
            },
        },
        UPSERT: {
            TITLE: "Crear Categoría",
            EDIT_TITLE: "Editar Categoría",
            ACCEPT: "Aceptar",
            CANCEL: "Cancelar",
            FORM: {
                NAME: {
                    LABEL: "Nombre",
                    PLACEHOLDER: "Ingrese nombre",
                },
                DESCRIPTION: {
                    LABEL: "Descripcion",
                    PLACEHOLDER: "Ingrese descripción",
                },
            }
        },
    },
}

export const productPropertiesDictionary = {}
productPropertiesDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
productPropertiesDictionary[Languages.SPANISH_US] = DescriptionsEsUs