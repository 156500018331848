import { SystemDescriptions } from "src/common/descriptions";
import { RegularizationType } from "src/common/models/stockRegularization";
import { searchableTableUtils } from "src/components/SearchableTable/utils"

const descriptions = SystemDescriptions.PAGES.REGULARIZATION.CREATE

export enum ColumnsKeys {
    ID = 'productId',
    DESCRIPTION = 'product',
    MEASSURE = 'meassure',
    TYPE = 'type',
    QTY = 'qty',
    PREVIOUS = 'previousStock',
    NEW = 'newStock',
    COMMENT = 'comment',
    ACTIONS = 'actions',
}

export const tableColumns: any = [
    {
        title: descriptions.ITEMS.PRODUCT_ID,
        dataIndex: ColumnsKeys.ID,
        key: ColumnsKeys.ID,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.ITEMS.DESCRIPTION,
        dataIndex: ColumnsKeys.DESCRIPTION,
        key: ColumnsKeys.DESCRIPTION,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.ITEMS.MEASSURE,
        dataIndex: ColumnsKeys.MEASSURE,
        key: ColumnsKeys.MEASSURE,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.ITEMS.TYPE,
        dataIndex: ColumnsKeys.TYPE,
        key: ColumnsKeys.TYPE,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => {
            switch (value) {
                case RegularizationType.ADD:
                    return descriptions.TYPE_ADD
                case RegularizationType.REST:
                    return descriptions.TYPE_REST
                default:
                    break;
            }
            return ''
        }
    },
    {
        title: descriptions.ITEMS.PREVIOUS,
        dataIndex: ColumnsKeys.PREVIOUS,
        key: ColumnsKeys.PREVIOUS,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.ITEMS.QTY,
        dataIndex: ColumnsKeys.QTY,
        key: ColumnsKeys.QTY,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.ITEMS.NEW,
        dataIndex: ColumnsKeys.NEW,
        key: ColumnsKeys.NEW,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.ITEMS.COMMENT,
        dataIndex: ColumnsKeys.COMMENT,
        key: ColumnsKeys.COMMENT,
        align: searchableTableUtils.alignment.centerAlign,
    },
];