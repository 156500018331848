import { Action } from 'redux'
import * as Actions from './actionTypes'
import { GetProductsFilters, Product } from 'src/common/models/product'
import { WarehouseStock } from 'src/common/models/warehouseStock'

export interface GetProductsAction extends Action {
    filters?: GetProductsFilters
}
export interface GetProductsSuccessAction extends Action {
    products: Product[]
}
export interface ErrorAction extends Action {
    error?
}
export const getProductsApi = (filters?: GetProductsFilters): GetProductsAction => ({
    type: Actions.GET_PRODUCTS_API,
    filters,
})
export const getProductsSuccess = (products: Product[]): GetProductsSuccessAction => ({
    type: Actions.GET_PRODUCTS_API_SUCCESS,
    products,
})
export const getProductsError = (error?): ErrorAction => ({
    type: Actions.GET_PRODUCTS_API_ERROR,
    error,
})

export interface CreateProductAction extends Action {
    product: Product
}
export interface CreateProductSuccessAction extends Action {
    product: Product
}
export const createProductApi = (product: Product): CreateProductAction => ({
    type: Actions.CREATE_PRODUCT_API,
    product,
})
export const createProductSuccess = (product: Product): CreateProductSuccessAction => ({
    type: Actions.CREATE_PRODUCT_API_SUCCESS,
    product,
})
export const createProductError = (error?): ErrorAction => ({
    type: Actions.CREATE_PRODUCT_API_ERROR,
    error,
})

export interface GetProductByIdAction extends Action {
    id: number
}
export interface GetProductByIdSuccessAction extends Action {
    product: Product
}
export const getProductByIdApi = (id: number): GetProductByIdAction => ({
    type: Actions.GET_PRODUCT_BY_ID_API,
    id,
})
export const getProductByIdSuccess = (product: Product): GetProductByIdSuccessAction => ({
    type: Actions.GET_PRODUCT_BY_ID_API_SUCCESS,
    product,
})
export const getProductByIdError = (error?): ErrorAction => ({
    type: Actions.GET_PRODUCT_BY_ID_API_ERROR,
    error,
})

export interface UpdateProductAction extends Action {
    product: Product
}
export interface UpdateProductSuccessAction extends Action {
    product: Product
}
export const updateProductApi = (product: Product): UpdateProductAction => ({
    type: Actions.UPDATE_PRODUCT_API,
    product,
})
export const updateProductSuccess = (product: Product): UpdateProductSuccessAction => ({
    type: Actions.UPDATE_PRODUCT_API_SUCCESS,
    product,
})
export const updateProductError = (error?): ErrorAction => ({
    type: Actions.UPDATE_PRODUCT_API_ERROR,
    error,
})

export interface GetProductsByBatchAction extends Action {
    filters?: GetProductsFilters
}
export interface GetProductsByBatchSuccessAction extends Action {
    products: Product[]
}
export const getProductsByBatchApi = (filters?: GetProductsFilters): GetProductsByBatchAction => ({
    type: Actions.GET_PRODUCTS_BY_BATCH_API,
    filters,
})
export const getProductsByBatchSuccess = (products: Product[]): GetProductsByBatchSuccessAction => ({
    type: Actions.GET_PRODUCTS_BY_BATCH_API_SUCCESS,
    products,
})
export const getProductsByBatchError = (error?): ErrorAction => ({
    type: Actions.GET_PRODUCTS_BY_BATCH_API_ERROR,
    error,
})

export const cleanProductsByBatchApi = (): Action => ({
    type: Actions.CLEAN_PRODUCTS_BY_BATCH,
})

export interface GetWarehouseStockAction extends Action {
    warehouseId: number
    isStockValuated: boolean
}
export interface GetWarehouseStockSuccessAction extends Action {
    warehouseStock: WarehouseStock[]
}
export const getWarehouseStockApi = (warehouseId: number, isStockValuated: boolean): GetWarehouseStockAction => ({
    type: Actions.GET_WAREHOUSE_STOCK_API,
    warehouseId,
    isStockValuated,
})
export const getWarehouseStockSuccess = (warehouseStock: WarehouseStock[]): GetWarehouseStockSuccessAction => ({
    type: Actions.GET_WAREHOUSE_STOCK_API_SUCCESS,
    warehouseStock,
})
export const getWarehouseStockError = (error?): ErrorAction => ({
    type: Actions.GET_WAREHOUSE_STOCK_API_ERROR,
    error,
})