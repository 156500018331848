import { ErrorAction, GetProductSettingsSuccessAction } from "./action";
import { parseDataKey } from "src/common/util";
import { GET_PRODUCT_SETTINGS_API, GET_PRODUCT_SETTINGS_ERROR, GET_PRODUCT_SETTINGS_SUCCESS, UPSERT_PRODUCT_BRAND_API, UPSERT_PRODUCT_BRAND_SUCCESS, UPSERT_PRODUCT_CATEGORY_API, UPSERT_PRODUCT_CATEGORY_ERROR, UPSERT_PRODUCT_CATEGORY_SUCCESS, UPSERT_PRODUCT_SUBBRAND_API, UPSERT_PRODUCT_SUBBRAND_ERROR, UPSERT_PRODUCT_SUBBRAND_SUCCESS } from "./actionTypes";
import { ProductSettingsError, ProductSettingsErrorOrigin, ProductSettingsType } from "./types";
import { ProductBrand } from "src/common/models/productBrand";
import { ProductSubBrand } from "src/common/models/productSubBrand";
import { ProductCategory } from "src/common/models/productCategory";
import { ProductMeassure } from "src/common/models/productMeassure";

export interface ProductSettingsState {
    brands?: ProductBrand[]
    subBrands?: ProductSubBrand[]
    categories?: ProductCategory[]
    meassures?: ProductMeassure[]
    error?: ProductSettingsError
    upsertProductSettingSuccess?: ProductSettingsType
}

const initialState: ProductSettingsState = {
}

const productSettingsReducer = (state: ProductSettingsState = initialState, action: any): ProductSettingsState => {
    switch (action.type) {
        case GET_PRODUCT_SETTINGS_API: {
            return {
                ...state,
                error: undefined
            }
        }
        case GET_PRODUCT_SETTINGS_SUCCESS: {
            const successAction = action as GetProductSettingsSuccessAction;
            const brands = successAction.productSettings.brands
            const subBrands = successAction.productSettings.subBrands
            const meassures = successAction.productSettings.meassures
            const categories = successAction.productSettings.categories

            return {
                ...state,
                brands: brands ? parseDataKey(brands) : undefined,
                subBrands: subBrands ? parseDataKey(subBrands) : undefined,
                meassures: meassures ? parseDataKey(meassures) : undefined,
                categories: categories ? parseDataKey(categories) : undefined,
                error: undefined
            }
        }
        case GET_PRODUCT_SETTINGS_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: ProductSettingsErrorOrigin.GET_PRODUCT_SETTINGS,
                    detail: errorAction.error
                }
            }
        }
        case UPSERT_PRODUCT_BRAND_API: {
            return {
                ...state,
                upsertProductSettingSuccess: undefined,
                error: undefined,
            }
        }
        case UPSERT_PRODUCT_BRAND_SUCCESS: {
            return {
                ...state,
                upsertProductSettingSuccess: ProductSettingsType.BRAND,
            }
        }
        case GET_PRODUCT_SETTINGS_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: ProductSettingsErrorOrigin.UPSERT_PRODUCT_BRAND,
                    detail: errorAction.error
                }
            }
        }
        case UPSERT_PRODUCT_SUBBRAND_API: {
            return {
                ...state,
                upsertProductSettingSuccess: undefined,
                error: undefined,
            }
        }
        case UPSERT_PRODUCT_SUBBRAND_SUCCESS: {
            return {
                ...state,
                upsertProductSettingSuccess: ProductSettingsType.SUBBRAND,
            }
        }
        case UPSERT_PRODUCT_SUBBRAND_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: ProductSettingsErrorOrigin.UPSERT_PRODUCT_SUBBRAND,
                    detail: errorAction.error
                }
            }
        }
        case UPSERT_PRODUCT_CATEGORY_API: {
            return {
                ...state,
                upsertProductSettingSuccess: undefined,
                error: undefined,
            }
        }
        case UPSERT_PRODUCT_CATEGORY_SUCCESS: {
            return {
                ...state,
                upsertProductSettingSuccess: ProductSettingsType.CATEGORY,
            }
        }
        case UPSERT_PRODUCT_CATEGORY_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: ProductSettingsErrorOrigin.UPSERT_PRODUCT_CATEGORY,
                    detail: errorAction.error
                }
            }
        }
    }
    return state;
};

export default productSettingsReducer
