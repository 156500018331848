import { Alert, Form, Input, Row, Select } from "antd"
import { useSelector } from "react-redux"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { SystemDescriptions } from "src/common/descriptions"
import { ProductBrand } from "src/common/models/productBrand"
import { ProductSubBrand } from "src/common/models/productSubBrand"
import { getInputRequiredRule, selectFilterOption } from "src/common/util"
import { Col24 } from "src/components/Columns"
import { FormActionButtons } from "src/components/FormActionButtons"
import { UPSERT_PRODUCT_SUBBRAND_API } from "src/features/ProductSettings/state/actionTypes"
import { ProductSettingsError } from "src/features/ProductSettings/state/types"
import { RootState } from "src/state/reducer"

interface ReduxProps {
    isUpsertingProductBrand: boolean
    error?: ProductSettingsError
    brands?: ProductBrand[]
}

interface Props {
    initialValues?: ProductSubBrand
    onCancel: () => void
    onFinish: (productBrand: Partial<ProductSubBrand>) => void
}

export const ProductSubBrandForm = (props: Props) => {
    const descriptions = SystemDescriptions.PAGES.PRODUCT.PRODUCT_PROPERTIES.SUBBRANDS.UPSERT
    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isUpsertingProductBrand: serviceLoading(state, [UPSERT_PRODUCT_SUBBRAND_API]),
        error: state.productSettings.error,
        brands: state.productSettings.brands,
    }))

    const [form] = Form.useForm();

    const handleCancelClick = () => {
        props.onCancel()
    }

    const getButtonStatus = (): boolean => {
        return props.initialValues
            ? false
            : !form.isFieldsTouched(true) ||
            form.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0 || reduxProps.isUpsertingProductBrand
    }

    const renderButtons = () => (
        <FormActionButtons
            cancelText={descriptions.CANCEL}
            actionText={descriptions.ACCEPT}
            actionDisabled={getButtonStatus}
            onCancel={handleCancelClick}
            actionLoading={reduxProps.isUpsertingProductBrand}
        />
    )

    const onFinish = (formValues) => {
        const productBrand: Partial<ProductSubBrand> = {
            name: formValues.name,
            description: formValues.description,
            brandId: formValues.brand,
        }

        props.onFinish(productBrand)
    }

    const renderForm = () => {
        return <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            autoComplete={'none'}
            onFinish={onFinish}
        >
            <Row gutter={[16, 8]}>
                <Col24>
                    <Form.Item
                        label={descriptions.FORM.NAME.LABEL}
                        name={"name"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.NAME.LABEL)
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.NAME.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col24>
                <Col24>
                    <Form.Item
                        label={descriptions.FORM.BRAND.LABEL}
                        name={"brand"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.BRAND.LABEL)
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            options={reduxProps?.brands?.map(brand => ({
                                value: brand.id.toString(),
                                label: brand.name,
                            }))}
                            placeholder={descriptions.FORM.BRAND.PLACEHOLDER}
                            filterOption={selectFilterOption}
                            showSearch
                            optionFilterProp="children"
                        />
                    </Form.Item>
                </Col24>
                <Col24>
                    <Form.Item
                        label={descriptions.FORM.DESCRIPTION.LABEL}
                        name={"description"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.DESCRIPTION.LABEL)
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.DESCRIPTION.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col24>
            </Row>
            {
                reduxProps.error &&
                <Alert
                    message={"ERROR"}
                    showIcon
                    description={reduxProps.error?.detail.message ?? commonDescriptions.FORM.UNKNOWN_ERROR}
                    type="error"
                    style={{
                        marginBottom: 12,
                    }}
                />
            }
            {renderButtons()}
        </Form>
    }

    return (
        renderForm()
    )
}
