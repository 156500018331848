import { Languages } from "src/common/descriptions/types";
import { WarehouseStockDescriptions } from "./types";

const DescriptionsEnUs: WarehouseStockDescriptions = {
    TITLE: "Warehouse Stock",
    TABLE: {
        ACTION_BUTTON: "",
        SEARCH_PLACEHOLDER: "Search",
        LOADING_DATA: "Loading data...",
    },
    TABLE_COLUMNS: {
        KEY: "No",
        PRODUCT_ID: "ID",
        DESCRIPTION: "Description",
        MEASSURE: "Meassure",
        STOCK: "Stock",
        COSTE: "Coste",
        VALUE: "Value",
    },
    EXPORT_DATA: "Export Data",
    PRINT: "Print",
    REPORT: {
        DATE: "Up to date",
        PRINTED_AT: "Printed at",
        PAGE: "Page",
    },
}

const DescriptionsEsUs: WarehouseStockDescriptions = {
    TITLE: "Existencias de Producto",
    TABLE: {
        ACTION_BUTTON: "",
        SEARCH_PLACEHOLDER: "Buscar",
        LOADING_DATA: "Cargando información...",
    },
    TABLE_COLUMNS: {
        KEY: "No",
        PRODUCT_ID: "Código",
        DESCRIPTION: "Descripción",
        MEASSURE: "Presentación",
        STOCK: "Existencia",
        COSTE: "Costo",
        VALUE: "Valorizado",
    },
    EXPORT_DATA: "Exportar Datos",
    PRINT: "Imprimir",
    REPORT: {
        DATE: "A la fecha",
        PRINTED_AT: "Impreso",
        PAGE: "Página",
    },
}

export const warehouseStockDictionary = {}
warehouseStockDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
warehouseStockDictionary[Languages.SPANISH_US] = DescriptionsEsUs