import { SystemConstants } from "src/common/constants";

const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const GET_SUPPLIERS_API = GET_SUPPLIERS + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_SUPPLIERS_API_SUCCESS = GET_SUPPLIERS + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_SUPPLIERS_API_ERROR = GET_SUPPLIERS + SystemConstants.API_LOADER.ERROR_SUFFIX;

const UPSERT_SUPPLIER = 'UPSERT_SUPPLIER';
export const UPSERT_SUPPLIER_API = UPSERT_SUPPLIER + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const UPSERT_SUPPLIER_API_SUCCESS = UPSERT_SUPPLIER + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const UPSERT_SUPPLIER_API_ERROR = UPSERT_SUPPLIER + SystemConstants.API_LOADER.ERROR_SUFFIX;