import { SystemConstants } from "src/common/constants";

const GET_PRODUCT_SETTINGS = 'GET_PRODUCT_SETTINGS';
export const GET_PRODUCT_SETTINGS_API = GET_PRODUCT_SETTINGS + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_PRODUCT_SETTINGS_SUCCESS = GET_PRODUCT_SETTINGS + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_PRODUCT_SETTINGS_ERROR = GET_PRODUCT_SETTINGS + SystemConstants.API_LOADER.ERROR_SUFFIX;

const UPSERT_PRODUCT_BRAND = 'UPSERT_PRODUCT_BRAND';
export const UPSERT_PRODUCT_BRAND_API = UPSERT_PRODUCT_BRAND + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const UPSERT_PRODUCT_BRAND_SUCCESS = UPSERT_PRODUCT_BRAND + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const UPSERT_PRODUCT_BRAND_ERROR = UPSERT_PRODUCT_BRAND + SystemConstants.API_LOADER.ERROR_SUFFIX;

const UPSERT_PRODUCT_SUBBRAND = 'UPSERT_PRODUCT_SUBBRAND';
export const UPSERT_PRODUCT_SUBBRAND_API = UPSERT_PRODUCT_SUBBRAND + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const UPSERT_PRODUCT_SUBBRAND_SUCCESS = UPSERT_PRODUCT_SUBBRAND + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const UPSERT_PRODUCT_SUBBRAND_ERROR = UPSERT_PRODUCT_SUBBRAND + SystemConstants.API_LOADER.ERROR_SUFFIX;

const UPSERT_PRODUCT_CATEGORY = 'UPSERT_PRODUCT_CATEGORY';
export const UPSERT_PRODUCT_CATEGORY_API = UPSERT_PRODUCT_CATEGORY + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const UPSERT_PRODUCT_CATEGORY_SUCCESS = UPSERT_PRODUCT_CATEGORY + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const UPSERT_PRODUCT_CATEGORY_ERROR = UPSERT_PRODUCT_CATEGORY + SystemConstants.API_LOADER.ERROR_SUFFIX;