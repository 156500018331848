import { Form, Input, Modal, Row, Select } from "antd"
import { Product } from "src/common/models/product"
import { RegularizationType, StockRegularizationTransaction } from "src/common/models/stockRegularization"
import * as Styles from './styles'
import { Col12, Col24, Col6, Col8 } from "src/components/Columns"
import { FormActionButtons } from "src/components/FormActionButtons"
import { SystemDescriptions } from "src/common/descriptions"
import { getInputRequiredRule, selectFilterOption } from "src/common/util"
import { useEffect, useState } from "react"
import { ProductPresentation } from "src/common/models/productPresentation"

interface Props {
    open: boolean
    onAccept: (detail: StockRegularizationTransaction) => void
    onCancel: () => void
    product?: Product
    detail?: StockRegularizationTransaction
}

export const ProductRegularizationModal = (props: Props) => {
    const [form] = Form.useForm();

    const [isMeassureSelected, setIsMeassureSelected] = useState<boolean>(false)
    const [isTypeSelected, setIsTypeSelected] = useState<boolean>(false)
    const [_, setSelectedMeassure] = useState<ProductPresentation | undefined>()

    useEffect(() => {
        if (!props.product) {
            return
        }

        if (props.product) {
            const product = props.product

            form.setFieldsValue({
                description: product.name,
                qty: undefined,
                meassure: undefined,
                type: undefined,
                previousStock: 0,
            })
            setIsMeassureSelected(false)
            setIsTypeSelected(false)
        }

        if (props.detail) {
            const detail = props.detail

            const foundMeassure = props.product.presentations.find(candidate => candidate.meassureId === props.detail?.meassureId)

            form.setFieldsValue({
                qty: detail?.qty ?? 1,
                meassure: foundMeassure?.ref,
                previousStock: 1000, // CALCULATE THIS
                newStock: 1000, // CALCULATE THIS
                type: detail.type,
            })
            setIsMeassureSelected(true)
            setIsTypeSelected(true)
            setSelectedMeassure(foundMeassure)
        }
    }, [props.product, props.detail])

    const descriptions = SystemDescriptions.PAGES.REGULARIZATION.CREATE

    const renderHeader = () => {
        return <Styles.Header>
            <Styles.HeaderData>
                <Styles.Title>Add Product for Regularization</Styles.Title>
            </Styles.HeaderData>
        </Styles.Header>
    }

    const handleOnFinish = (formValues) => {
        const foundPresentation = props?.product?.presentations.find(candidate => candidate.ref === formValues.meassure)

        if (!foundPresentation || !props.product) {
            return
        }

        const detail: StockRegularizationTransaction = {
            productId: props.product?.id!,
            product: props.product.name,
            meassure: foundPresentation.meassure || '',
            qty: Number(formValues.qty),
            comment: formValues.comment || 'NA',
            previousStock: formValues.previousStock,
            newStock: formValues.newStock,
            meassureId: foundPresentation.meassureId,
            type: formValues.type,
            coste: props.product.coste,
            warehouseId: 1,
        }

        props.onAccept(detail)
        cleanForm()
    }

    const getButtonStatus = (): boolean => {
        return !form.isFieldsTouched(true) ||
            form.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0
    }

    const cleanForm = () => {
        form.resetFields()
    }

    const renderButtons = () => (
        <FormActionButtons
            cancelText={descriptions.CANCEL_BUTTON}
            actionText={descriptions.ACCEPT_BUTTON}
            onCancel={() => {
                cleanForm()
                props.onCancel()
            }}
            actionDisabled={getButtonStatus}
        />
    )

    const handleMeassureChange = (value) => {
        setIsMeassureSelected(true)
        const stock = getStockByMeasure(props?.product?.stock || 0, value)

        form.setFieldsValue({
            previousStock: stock,
        })
    }

    const handleTypeChange = () => {
        setIsTypeSelected(true)
        form.setFieldsValue({
            qty: undefined,
        })
    }

    const qtyValidator = (value) => {
        const integerPattern = /^[1-9]\d*$/;
        if (integerPattern.test(value)) {
            return Promise.resolve()
        } else {
            return Promise.reject(descriptions.FORM.ERRORS.INVALID_QTY)
        }
    }

    const calculateMinimalUnityValues = (qty: number, meassureId: string): { qtyMinimal: number } => {
        let qtyMinimal: number = qty

        const foundPresentation = props?.product?.presentations.find(candidate => candidate.ref === meassureId)

        if (!foundPresentation) {
            throw new Error("Unable to found product presentation from detail")
        }

        if (foundPresentation.qty > 1) {
            qtyMinimal = qty * foundPresentation.qty
        }

        return {
            qtyMinimal: Number(qtyMinimal),
        }
    }

    const getStockByMeasure = (qty: number, meassureId: string): number => {
        const foundPresentation = props?.product?.presentations.find(candidate => candidate.ref === meassureId)

        if (!foundPresentation) {
            throw new Error("Unable to found product presentation from detail")
        }

        if (foundPresentation.qty === 1) {
            return qty
        }

        return qty / foundPresentation.qty
    }

    const stockValidator = (value) => {
        const { meassure, type } = form.getFieldsValue()

        if (type === RegularizationType.ADD) {
            return Promise.resolve()
        }

        const { qtyMinimal } = calculateMinimalUnityValues(value, meassure)

        if (props.product && (qtyMinimal > props.product.stock!)) {
            return Promise.reject(descriptions.FORM.ERRORS.NOT_ENOUGH_STOCK)
        } else {
            return Promise.resolve()
        }
    }

    const handleInputFocus = (event: any) => event.target.select();

    const handleChangeQty = () => {
        const { qty, previousStock, type } = form.getFieldsValue()

        const newStock: number = type === RegularizationType.ADD
            ? parseFloat(previousStock) + parseFloat(qty)
            : parseFloat(previousStock) - parseFloat(qty)

        form.setFieldsValue({
            newStock: newStock < 0 ? 0 : newStock,
        })
    }

    const renderForm = () => (
        <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            autoComplete={'none'}
            size="small"
            onFinish={handleOnFinish}
        >
            <Row gutter={[16, 0]}>
                <Col12>
                    <Form.Item
                        label={descriptions.FORM.PRODUCT.LABEL}
                        name={"description"}
                        validateTrigger="onBlur"
                    >
                        <Input readOnly />
                    </Form.Item>
                </Col12>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.MEASSURE.LABEL}
                        name={"meassure"}
                        validateTrigger="onBlur"
                        rules={[
                            getInputRequiredRule(descriptions.FORM.MEASSURE.LABEL),
                        ]}
                    >
                        <Select
                            options={props.product?.presentations?.map(presentation => ({
                                value: `${presentation.ref}` ?? '',
                                label: `${presentation.meassure}` ?? '',
                            }))}
                            placeholder={descriptions.FORM.MEASSURE.PLACEHOLDER}
                            filterOption={selectFilterOption}
                            showSearch
                            optionFilterProp="children"
                            onChange={handleMeassureChange}
                        />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.TYPE.LABEL}
                        name={"type"}
                        validateTrigger="onBlur"
                        rules={[
                            getInputRequiredRule(descriptions.FORM.MEASSURE.LABEL),
                        ]}
                    >
                        <Select
                            options={[
                                {
                                    value: RegularizationType.ADD,
                                    label: descriptions.TYPE_ADD,
                                },
                                {
                                    value: RegularizationType.REST,
                                    label: descriptions.TYPE_REST,
                                },
                            ]}
                            placeholder={descriptions.FORM.TYPE.PLACEHOLDER}
                            optionFilterProp="children"
                            onChange={handleTypeChange}
                        />
                    </Form.Item>
                </Col6>
                <Col8>
                    <>
                        <Form.Item
                            label={descriptions.FORM.QTY.LABEL}
                            name={"qty"}
                            validateTrigger={["onBlur", "onChange"]}
                            rules={[
                                getInputRequiredRule(descriptions.FORM.QTY.LABEL),
                                {
                                    validator: (_, value) => {
                                        return qtyValidator(value)
                                    }
                                },
                                {
                                    validator: (_, value) => {
                                        return stockValidator(value)
                                    }
                                }
                            ]}
                        >
                            <Input
                                style={{ textAlign: 'center' }}
                                placeholder={descriptions.FORM.QTY.PLACEHOLDER}
                                type="number"
                                min={1}
                                onChange={handleChangeQty}
                                disabled={!isMeassureSelected || !isTypeSelected}
                                onFocus={handleInputFocus}
                                autoComplete="none"
                            />
                        </Form.Item>
                    </>
                </Col8>
                <Col8>
                    <Form.Item
                        label={descriptions.FORM.PREVIOUS_STOCK.LABEL}
                        name={"previousStock"}
                        validateTrigger="onBlur"
                    >
                        <Input placeholder={descriptions.FORM.PREVIOUS_STOCK.PLACEHOLDER} readOnly />
                    </Form.Item>
                </Col8>
                <Col8>
                    <Form.Item
                        label={descriptions.FORM.PREVIOUS_STOCK.LABEL}
                        name={"newStock"}
                        validateTrigger="onBlur"
                    >
                        <Input placeholder={descriptions.FORM.PREVIOUS_STOCK.PLACEHOLDER} readOnly />
                    </Form.Item>
                </Col8>
                <Col24>
                    <Form.Item
                        label={descriptions.FORM.COMMENT.LABEL}
                        name={"comment"}
                        validateTrigger="onBlur"
                    >
                        <Input />
                    </Form.Item>
                </Col24>
            </Row>
            {renderButtons()}
        </Form>
    )

    return (
        props.product
            ? <Modal
                centered
                open={props.open}
                width={1000}
                footer={null}
                closeIcon={false}
                destroyOnClose
            >
                {renderHeader()}
                {renderForm()}
            </Modal>
            : null
    )
}
