import { Tabs } from "antd"
import { PageCard } from "src/common/styles/styles"
import { ProductBrands } from "./ProductBrands"
import { ProductSubBrands } from "./ProductSubBrands"
import { ProductCategories } from "./ProductCategories"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { getProductSettings } from "../state/action"
import { SystemDescriptions } from "src/common/descriptions"

export enum ProductProperty {
    BRAND = 'BRAND',
    SUBBRAND = 'SUBBRAND',
    CATEGORY = 'CATEGORY',
}

export const ProductSettings = () => {
    const dispatch = useDispatch()

    const descriptions = SystemDescriptions.PAGES.PRODUCT.PRODUCT_PROPERTIES

    useEffect(() => {
        dispatch(getProductSettings())
    }, [])

    return (
        <PageCard
            title={''}
        >
            <Tabs
                defaultActiveKey={ProductProperty.BRAND}
                size={"small"}
                items={[
                    {
                        label: descriptions.BRANDS.TITLE,
                        key: ProductProperty.BRAND,
                        children: <ProductBrands />,
                    },
                    {
                        label: descriptions.SUBBRANDS.TITLE,
                        key: ProductProperty.SUBBRAND,
                        children: <ProductSubBrands />,
                    },
                    {
                        label: descriptions.CATEGORIES.TITLE,
                        key: ProductProperty.CATEGORY,
                        children: <ProductCategories />,
                    },
                ]}
            />
        </PageCard>
    )
}
