import { ButtonHTMLType } from "antd/es/button"
import * as Styles from './styles'
import { ActionButtonType } from "./types"

interface Props {
    actionButtonType?: ActionButtonType
    label: string
    htmlType?: ButtonHTMLType
    onClick?: () => void
    disabled?: boolean
    styles?: Object
    loading?: boolean
}

export const ActionButton = (props: Props) => {

    return (
        <Styles.ActionButton
            htmlType={props.htmlType ?? undefined}
            onClick={props.onClick}
            actionButtonType={props.actionButtonType ?? ActionButtonType.PRIMARY}
            disabled={props.disabled}
            style={props.styles}
            loading={props.loading}
        >
            {props.label}
        </Styles.ActionButton>
    )
}
