import { SystemDescriptions } from "src/common/descriptions";
import { formatToDisplayDatetime, } from "src/common/util";
import { searchableTableUtils } from "src/components/SearchableTable/utils";

const descriptions = SystemDescriptions.PAGES.REGULARIZATION.RECORDS

export enum ColumnsKeys {
    NO = 'key',
    DATETIME = 'datetime',
    PRODUCT = 'product',
    MEASSURE = 'meassure',
    QTY = 'qty',
    PREVIOUS_STOCK = 'previousStock',
    NEW_STOCK = 'newStock',
    ACTIONS = 'actions',
}

export const TABLE_COLUMNS: any = [
    {
        title: descriptions.TABLE_COLUMNS.KEY,
        dataIndex: ColumnsKeys.NO,
        key: ColumnsKeys.NO,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.DATETIME,
        dataIndex: ColumnsKeys.DATETIME,
        key: ColumnsKeys.DATETIME,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToDisplayDatetime(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.PRODUCT,
        dataIndex: ColumnsKeys.PRODUCT,
        key: ColumnsKeys.PRODUCT,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.MEASSURE,
        dataIndex: ColumnsKeys.MEASSURE,
        key: ColumnsKeys.MEASSURE,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.QTY,
        dataIndex: ColumnsKeys.QTY,
        key: ColumnsKeys.QTY,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.PREVIOUS_STOCK,
        dataIndex: ColumnsKeys.PREVIOUS_STOCK,
        key: ColumnsKeys.PREVIOUS_STOCK,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.NEW_STOCK,
        dataIndex: ColumnsKeys.NEW_STOCK,
        key: ColumnsKeys.NEW_STOCK,
        align: searchableTableUtils.alignment.centerAlign,
    },
];