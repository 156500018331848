import { Collapse, CollapseProps, Modal, theme } from "antd"
import { CaretRightOutlined } from '@ant-design/icons';
import { StockRegularization } from "src/common/models/stockRegularization";
import { SystemDescriptions } from "src/common/descriptions";
import * as Styles from './styles'

interface Props {
    open: boolean
    regularizationResults: StockRegularization[]
    onAccept: () => void
}

export const RegularizationResults = (props: Props) => {
    const { token } = theme.useToken();

    const descriptions = SystemDescriptions.PAGES.REGULARIZATION.CREATE

    const panelSuccess: React.CSSProperties = {
        marginBottom: 24,
        background: '#F6FFEC',
        borderRadius: token.borderRadiusLG,
    };

    const panelError: React.CSSProperties = {
        marginBottom: 24,
        background: '#F6FFEC',
        borderRadius: token.borderRadiusLG,
    };

    const getItems: () => CollapseProps['items'] = () => props.regularizationResults.map((result, idx) => {
        return {
            key: idx,
            label: `${result.product} ${result.meassure}`,
            children: result?.errorMessage || descriptions.SUCCESS_MODAL.DESCRIPTION,
            style: result.id ? panelSuccess : panelError,
        }
    })

    const renderContent = () => (
        <>
            <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} rev={undefined} />}
                style={{ background: token.colorBgContainer }}
                items={getItems()}
            />
            <Styles.Container key={1}>
                <Styles.ActionButton
                    onClick={props.onAccept}
                    type="primary"
                    key="buy"
                >
                    {descriptions.SUCCESS_MODAL.PRIMARY_LABEL}
                </Styles.ActionButton>
            </Styles.Container>
        </>
    )

    return (
        <Modal
            centered
            open={props.open}
            width={1000}
            footer={null}
            closeIcon={false}
            destroyOnClose
            title={descriptions.SUCCESS_MODAL.TITLE}
        >
            {renderContent()}
        </Modal>
    )
}
