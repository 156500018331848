import { SystemDescriptions } from "src/common/descriptions";
import { formatToCurrency, formatToDisplayDatetime } from "src/common/util";
import { SummaryType } from "src/components/SearchableTable/types";
import { searchableTableUtils } from "src/components/SearchableTable/utils";

const descriptions = SystemDescriptions.PAGES.REPORTS.PRODUCT_KARDEX

export enum ColumnsKeys {
    KEY = 'key',
    DATETIME = 'datetime',
    PRODUCT_ID = 'productId',
    MEASSURE = 'meassure',
    DESCRIPTION = 'product',
    DOCUMENT = 'document',
    PREVIOUS = 'previous',
    INCOME = 'income',
    OUTCOME = 'outcome',
    BALANCE = 'balance',
    TOTAL = 'total',
    COSTE = 'lastCoste',
    UTILITY = 'utility',
}

export const TABLE_COLUMNS: any = [
    {
        title: descriptions.TABLE_COLUMNS.KEY,
        dataIndex: ColumnsKeys.KEY,
        key: ColumnsKeys.KEY,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.DATETIME,
        dataIndex: ColumnsKeys.DATETIME,
        key: ColumnsKeys.DATETIME,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToDisplayDatetime(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.PRODUCT_ID,
        dataIndex: ColumnsKeys.PRODUCT_ID,
        key: ColumnsKeys.PRODUCT_ID,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.MEASSURE,
        dataIndex: ColumnsKeys.MEASSURE,
        key: ColumnsKeys.MEASSURE,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.DESCRIPTION,
        dataIndex: ColumnsKeys.DESCRIPTION,
        key: ColumnsKeys.DESCRIPTION,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.DOCUMENT,
        dataIndex: ColumnsKeys.DOCUMENT,
        key: ColumnsKeys.DOCUMENT,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.PREVIOUS,
        dataIndex: ColumnsKeys.PREVIOUS,
        key: ColumnsKeys.PREVIOUS,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.INCOME,
        dataIndex: ColumnsKeys.INCOME,
        key: ColumnsKeys.INCOME,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.OUTCOME,
        dataIndex: ColumnsKeys.OUTCOME,
        key: ColumnsKeys.OUTCOME,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.BALANCE,
        dataIndex: ColumnsKeys.BALANCE,
        key: ColumnsKeys.BALANCE,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.TOTAL,
        dataIndex: ColumnsKeys.TOTAL,
        key: ColumnsKeys.TOTAL,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.COSTE,
        dataIndex: ColumnsKeys.COSTE,
        key: ColumnsKeys.COSTE,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.UTILITY,
        dataIndex: ColumnsKeys.UTILITY,
        key: ColumnsKeys.UTILITY,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
];

export const summaryColumns: Partial<Record<ColumnsKeys, SummaryType>> = {
    [ColumnsKeys.INCOME]: SummaryType.SUM,
    [ColumnsKeys.OUTCOME]: SummaryType.SUM,
    [ColumnsKeys.TOTAL]: SummaryType.SUM,
    [ColumnsKeys.COSTE]: SummaryType.SUM,
    [ColumnsKeys.UTILITY]: SummaryType.SUM,
}