import { Alert, Form, Row, Select } from "antd";
import { RegularizationError } from "../../state/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/reducer";
import { serviceLoading } from "src/common/apiLoader/state/selection";
import { CREATE_REGULARIZATION_API } from "../../state/actionTypes";
import { SystemDescriptions } from "src/common/descriptions";
import { Col24 } from "src/components/Columns";
import { selectFilterOption } from "src/common/util";
import { Product } from "src/common/models/product";
import { getProductsApi } from "src/features/Product/state/action";
import { useEffect, useRef, useState } from "react";
import { ProductRegularizationModal } from "../ProductRegularizationModal";
import { StockRegularizationTransaction } from "src/common/models/stockRegularization";

interface ReduxProps {
    error?: RegularizationError
    isCreatingRegularization: boolean
    products?: Product[]
}

interface Props {
    onFinish: (sale: any) => void
    onCancel: () => void
}

export const RegularizationForm = (props: Props) => {

    const dispatch = useDispatch()

    const selectRef = useRef<any>(null);
    const [selectedProduct, setSelectedProduct] = useState<Product | undefined>()
    const [selectedDetail, setSelectedDetail] = useState<StockRegularizationTransaction | undefined>(undefined)
    const [detailItems, setDetailItems] = useState<StockRegularizationTransaction[]>([])

    useEffect(() => {
        dispatch(getProductsApi({
            status: 'ACTIVE',
        }))
    }, [])

    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    const [form] = Form.useForm();

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        error: state.regularization.error,
        isCreatingRegularization: serviceLoading(state, [CREATE_REGULARIZATION_API]),
        products: state.product.products,
    }))

    const handleProductChange = (value) => {
        if (!value) {
            return
        }

        const foundProduct = reduxProps.products?.find(candidate => candidate.id == value)
        setSelectedProduct(foundProduct)
    }

    const handleAddProductAccept = (data: StockRegularizationTransaction) => {
        setSelectedProduct(undefined)
        setSelectedDetail(undefined)
        form.setFieldsValue({
            products: undefined,
        })
        setDetailItems([
            ...detailItems,
            data,
        ])
        props.onFinish(data)
    }

    const renderModals = () => (
        <ProductRegularizationModal
            open={!!selectedProduct}
            product={selectedProduct}
            onAccept={handleAddProductAccept}
            onCancel={function (): void {
                form.setFieldsValue({
                    products: undefined,
                })
                setSelectedProduct(undefined)
                setSelectedDetail(undefined)
            }}
            detail={selectedDetail}
        />
    )

    const renderForm = () => (
        <Form
            form={form}
            layout="horizontal"
            requiredMark={false}
            autoComplete={'none'}
            size="small"
        // onFinish={onFinish}
        >
            <Row gutter={[16, 0]}>
                <Col24>
                    <Form.Item
                        label={"Select product"}
                        name={"products"}
                    >
                        <Select
                            ref={!selectedProduct ? selectRef : undefined}
                            options={reduxProps.products?.map(product => ({
                                value: `${product.id}`,
                                label: `${product.name} - ${product.id}`,
                                disabled: (product.stock ?? 0) <= 0,
                            }))}
                            placeholder={"Products"}
                            filterOption={selectFilterOption}
                            showSearch
                            optionFilterProp="children"
                            onChange={handleProductChange}
                        />
                    </Form.Item>
                </Col24>
            </Row>
            {
                reduxProps.error &&
                <Alert
                    message={"Error"}
                    showIcon
                    description={reduxProps.error?.detail?.message ?? commonDescriptions.FORM.UNKNOWN_ERROR}
                    type="error"
                    style={{
                        marginBottom: 12,
                    }}
                />
            }
        </Form>
    )

    return (
        <>
            {renderForm()}
            {renderModals()}
        </>
    )
}
