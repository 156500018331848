import { PageCard } from "src/common/styles/styles"
import { RegularizationForm } from "./RegularizationForm"
import { SearchableTable } from "src/components/SearchableTable"
import { tableColumns } from "./config"
import { useState } from "react"
import { StockRegularization, StockRegularizationTransaction } from "src/common/models/stockRegularization"
import { parseDataKey } from "src/common/util"
import { CancelConfirmationModal } from "src/common/descriptions/components/cancelConfirmationModal"
import { SystemDescriptions } from "src/common/descriptions"
import { useNavigate } from "react-router"
import { Urls } from "src/common/urls"
import { RegularizationError } from "../state/types"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { CREATE_REGULARIZATION_API } from "../state/actionTypes"
import { FormActionButtons } from "src/components/FormActionButtons"
import { createRegularizationApi } from "../state/action"
import { Loader } from "src/components/Loader"
import { RegularizationResults } from "./RegularizationResults"

interface ReduxProps {
    error?: RegularizationError
    createRegularizationResults?: StockRegularization[]
    isCreatingRegularization: boolean
    regularizationResults?: StockRegularization[]
}

export const CreateRegularization = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        error: state.regularization.error,
        createRegularizationResults: state.regularization.createRegularizationResults,
        isCreatingRegularization: serviceLoading(state, [CREATE_REGULARIZATION_API]),
        regularizationResults: state.regularization.createRegularizationResults,
    }))

    const [regularizationItems, setRegularizationItems] = useState<StockRegularizationTransaction[]>([])
    const [modalConfirmationOpen, setModalConfirmationOpen] = useState<boolean>(false)
    const [modalCancelOpen, setModalCancelOpen] = useState<boolean>(false)
    const [actionButtonClicked, setActionButtonClicked] = useState<boolean>(false)

    const descriptions = SystemDescriptions.PAGES.REGULARIZATION.CREATE

    const TABLE_COLUMNS = [
        ...tableColumns,
    ]

    const onFinish = (data: StockRegularizationTransaction) => {
        const newData = parseDataKey([
            ...regularizationItems,
            data,
        ])

        setRegularizationItems(newData)
    }

    const renderForm = () => (
        <RegularizationForm
            onCancel={() => { }}
            onFinish={onFinish}
        />
    )

    const renderData = () => {
        return <SearchableTable
            items={regularizationItems}
            tableColumns={TABLE_COLUMNS}
            showLoader={false}
        />
    }

    const handleConfirmationAccept = () => {
        setModalConfirmationOpen(false)
        setActionButtonClicked(true)
        if (regularizationItems.length > 0) {
            dispatch(createRegularizationApi(regularizationItems as any))
        }
    }

    const handleCancelAccept = () => {
        navigate(Urls.FRONTEND.STOCK_REGULARIZATION.RECORDS)
    }

    const renderModals = () => (
        <>
            <CancelConfirmationModal
                isVisible={modalConfirmationOpen}
                onAccept={handleConfirmationAccept}
                onCancel={() => {
                    setModalConfirmationOpen(false)
                }}
                title={descriptions.CONFIRM_MODAL.TITLE}
                okText={descriptions.CONFIRM_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CONFIRM_MODAL.CANCEL_BUTTON}
                description={descriptions.CONFIRM_MODAL.DESCRIPTION}
            />
            <CancelConfirmationModal
                isVisible={modalCancelOpen}
                onAccept={handleCancelAccept}
                onCancel={() => { setModalCancelOpen(false) }}
                title={descriptions.CANCEL_MODAL.TITLE}
                okText={descriptions.CANCEL_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CANCEL_MODAL.CANCEL_BUTTON}
                description={descriptions.CANCEL_MODAL.DESCRIPTION}
            />
            <RegularizationResults
                open={(!!reduxProps.createRegularizationResults && reduxProps.createRegularizationResults?.length > 0) && actionButtonClicked}
                regularizationResults={reduxProps.regularizationResults || []}
                onAccept={() => {
                    navigate(Urls.FRONTEND.STOCK_REGULARIZATION.RECORDS)
                }}
            />
        </>
    )

    const getButtonStatus = (): boolean => {
        return regularizationItems.length === 0
    }

    const renderActionButtons = () => (
        <div
            style={{ marginTop: 12 }}
        >
            <FormActionButtons
                cancelText={descriptions.CANCEL_BUTTON}
                actionText={descriptions.SAVE_BUTTON}
                actionDisabled={getButtonStatus}
                onCancel={() => { setModalCancelOpen(true) }}
                onAccept={() => { setModalConfirmationOpen(true) }}
            />
        </div>
    )

    const renderLoader = () => (
        <Loader
            isVisible={reduxProps.isCreatingRegularization}
            title={descriptions.MAIN_ACTION_TITLE}
            description={descriptions.MAIN_ACTION_DESCRIPTION}
        />
    )

    return (
        <>
            <PageCard
                title={descriptions.TITLE}
                size="small"
            >
                {renderForm()}
                {renderData()}
                {renderActionButtons()}
            </PageCard>
            {renderModals()}
            {renderLoader()}
        </>
    )
}
