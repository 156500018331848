import { combineReducers } from 'redux'
import apiLoaderReducer, { ApiLoaderState } from 'src/common/apiLoader/state/reducer'
import securityReducer, { SecurityState } from 'src/features/Security/state/reducer'
import customerReducer, { CustomerState } from 'src/features/Customer/state/reducer'
import menuReducer, { MenuState } from 'src/components/Sidebar/state/reducer'
import supplierReducer, { SupplierState } from 'src/features/Supplier/state/reducer'
import userReducer, { UserState } from 'src/features/User/state/reducer'
import customerPaymentReducer, { CustomerPaymentState } from 'src/features/CustomerPayment/state/reducer'
import productReducer, { ProductState } from 'src/features/Product/state/reducer'
import productSettingsReducer, { ProductSettingsState } from 'src/features/ProductSettings/state/reducer'
import purchaseReducer, { PurchaseState } from 'src/features/Purchase/state/reducer'
import saleReducer, { SaleState } from 'src/features/Sale/state/reducer'
import reportReducer, { ReportState } from 'src/features/Reports/state/reducer'
import dteReducer, { DteState } from 'src/features/Dte/state/reducer'
import commonReducer, { CommonState } from 'src/common/state/reducer'
import routingReducer, { RoutingState } from 'src/features/Routing/state/reducer'
import regularizationReducer, { RegularizationState } from 'src/features/StockRegularization/state/reducer'

export interface RootState {
    apiLoader: ApiLoaderState
    security: SecurityState
    common: CommonState
    customer: CustomerState
    customerPayment: CustomerPaymentState
    dte: DteState,
    menu: MenuState
    product: ProductState
    productSettings: ProductSettingsState
    purchase: PurchaseState
    regularization: RegularizationState,
    report: ReportState
    routing: RoutingState,
    sale: SaleState
    supplier: SupplierState
    user: UserState
}

export default combineReducers<RootState>({
    apiLoader: apiLoaderReducer,
    security: securityReducer,
    common: commonReducer,
    customer: customerReducer,
    customerPayment: customerPaymentReducer,
    dte: dteReducer,
    menu: menuReducer,
    product: productReducer,
    productSettings: productSettingsReducer,
    purchase: purchaseReducer,
    regularization: regularizationReducer,
    report: reportReducer,
    routing: routingReducer,
    sale: saleReducer,
    supplier: supplierReducer,
    user: userReducer,
})