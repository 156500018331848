import { ApiError } from "src/features/Security/networking/types";

export enum RegularizationErrorOrigin {
    GET_REGULARIZATION_RECORDS = 'GET_REGULARIZATION_RECORDS',
    CREATE_REGULARIZATION = 'CREATE_REGULARIZATION',
}

export interface RegularizationError {
    type: RegularizationErrorOrigin,
    detail: ApiError
}