import { Supplier } from "src/common/models/supplier";
import { ErrorAction, getSuppliersSuccessAction } from "./action";
import { GET_SUPPLIERS_API, GET_SUPPLIERS_API_ERROR, GET_SUPPLIERS_API_SUCCESS, UPSERT_SUPPLIER_API, UPSERT_SUPPLIER_API_ERROR, UPSERT_SUPPLIER_API_SUCCESS } from "./actionTypes";
import { SupplierError, SupplierErrorOrigin } from "./types";
import { parseDataKey } from "src/common/util";

export interface SupplierState {
    suppliers: Supplier[]
    error?: SupplierError
    upsertSupplierSuccess: boolean
}

const initialState: SupplierState = {
    suppliers: [],
    upsertSupplierSuccess: false,
}

const supplierReducer = (state: SupplierState = initialState, action: any): SupplierState => {
    switch (action.type) {
        case GET_SUPPLIERS_API: {
            return {
                ...state,
                suppliers: [],
                error: undefined
            }
        }
        case GET_SUPPLIERS_API_SUCCESS: {
            const successAction = action as getSuppliersSuccessAction;

            return {
                ...state,
                suppliers: parseDataKey(successAction.suppliers),
                error: undefined
            }
        }
        case GET_SUPPLIERS_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                suppliers: [],
                error: {
                    type: SupplierErrorOrigin.GET_SUPPLIERS,
                    detail: errorAction.error
                }
            }
        }
        case UPSERT_SUPPLIER_API: {
            return {
                ...state,
                error: undefined,
                upsertSupplierSuccess: false,
            }
        }
        case UPSERT_SUPPLIER_API_SUCCESS: {
            return {
                ...state,
                upsertSupplierSuccess: true,
            }
        }
        case UPSERT_SUPPLIER_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                suppliers: [],
                error: {
                    type: SupplierErrorOrigin.UPSERT_SUPPLIER,
                    detail: errorAction.error
                }
            }
        }
    }
    return state;
};

export default supplierReducer;