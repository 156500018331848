import { useDispatch, useSelector } from "react-redux"
import { PageCard } from "src/common/styles/styles"
import { Loader } from "src/components/Loader"
import { SearchableTable } from "src/components/SearchableTable"
import { RootState } from "src/state/reducer"
import dayjs from 'dayjs'
import { DateRanges } from "src/components/SearchableTable/types"
import { Button, Dropdown, MenuProps } from "antd"
import { DownOutlined } from '@ant-design/icons'
import { searchableTableUtils } from "src/components/SearchableTable/utils"
import { TABLE_COLUMNS } from "./config"
import { StockRegularization } from "src/common/models/stockRegularization"
import { useEffect, useState } from "react"
import { getRegularizationRecordsApi } from "../state/action"
import moment from "moment"
import { SystemConstants } from "src/common/constants"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_REGULARIZATION_RECORDS_API } from "../state/actionTypes"
import { SystemDescriptions } from "src/common/descriptions"
import { useNavigate } from "react-router"
import { Urls } from "src/common/urls"

interface ReduxProps {
    isLoadingData: boolean
    regularizations: StockRegularization[]
}

export const RegularizationRecords = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [dateRanges, setDateRanges] = useState<DateRanges>({
        startDate: moment().format(SystemConstants.DATETIME_QUERY(true)),
        endDate: moment().format(SystemConstants.DATETIME_QUERY(false)),
    })

    const initialize = () => {
        dispatch(getRegularizationRecordsApi({
            dateFrom: dateRanges.startDate,
            dateTo: dateRanges.endDate,
        }))
    }

    useEffect(() => {
        initialize()
    }, [])

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_REGULARIZATION_RECORDS_API]),
        regularizations: state.regularization.regularizations || [],
    }))

    const descriptions = SystemDescriptions.PAGES.REGULARIZATION.RECORDS

    const handleActionButtonClick = () => {
        dispatch(getRegularizationRecordsApi({
            dateFrom: dateRanges.startDate,
            dateTo: dateRanges.endDate,
        }))
    }

    const handleDateRangesChange = (dateRanges: DateRanges) => {
        setDateRanges({
            startDate: moment(dateRanges.startDate).format(SystemConstants.DATETIME_QUERY(true)),
            endDate: moment(dateRanges.endDate).format(SystemConstants.DATETIME_QUERY(false)),
        })
    }

    const tblColumns = [
        ...TABLE_COLUMNS,
        {
            title: descriptions.TABLE_COLUMNS.ACTIONS,
            dataIndex: "actions",
            key: "actions",
            align: searchableTableUtils.alignment.centerAlign,
            render: (_, record: any) => { // TODO: FIX THIS
                const handleDetailClick = (saleId: number) => {
                    alert("under construction")
                }

                const items: MenuProps['items'] = [
                    {
                        key: '1',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleDetailClick(record.id!)}
                            >
                                {"Detail"}
                            </Button>
                        ),
                    },
                ];

                return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Dropdown menu={{ items }} placement="bottomRight" arrow >
                        <Button
                            size="small"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <DownOutlined rev={undefined} />
                        </Button>
                    </Dropdown>
                </div>
            },
        },
    ]

    const handleExtraAction = () => {
        navigate(Urls.FRONTEND.STOCK_REGULARIZATION.CREATE)
    }

    const renderExtraContent = () => (
        <Button
            color="default"
            size="small"
            onClick={handleExtraAction}
        >
            Create Regularization
        </Button>
    )

    const renderContent = () => (
        <PageCard
            size="small"
            title={descriptions.TITLE}
            extra={renderExtraContent()}
        >
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.regularizations}
                tableColumns={tblColumns}
                showLoader={reduxProps.isLoadingData}
                actionButtonLabel={descriptions.TABLE.ACTION_BUTTON}
                showActionButton
                showRangePicker
                rangePickerOnChange={handleDateRangesChange}
                rangePickerDefaultValues={[dayjs(), dayjs()]}
                actionButtonOnClick={handleActionButtonClick}
            />
        </PageCard >
    )

    return (
        <>
            {renderContent()}
            <Loader
                isVisible={reduxProps.isLoadingData}
            />
        </>
    )
}
