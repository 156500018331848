import { Languages } from "src/common/descriptions/types";
import { RegularizationRecordsDescriptions } from "./types";

const DescriptionsEnUs: RegularizationRecordsDescriptions = {
    TITLE: "Regularization Records",
    TABLE: {
        ACTION_BUTTON: "Get Records",
        SEARCH_PLACEHOLDER: "Search",
        LOADING_DATA: "Loading data",
    },
    TABLE_COLUMNS: {
        KEY: "No",
        DATETIME: "Datetime",
        PRODUCT: "Product",
        MEASSURE: "Meassure",
        QTY: "Qty",
        PREVIOUS_STOCK: "Previous",
        NEW_STOCK: "New Stock",
        ACTIONS: "Actions",
    },
    ACTIONS: {
        VIEW_DETAIL: "View Detail",
    },
}

const DescriptionsEsUs: RegularizationRecordsDescriptions = {
    TITLE: "Registro de Regularizaciones",
    TABLE: {
        ACTION_BUTTON: "Ver Registros",
        SEARCH_PLACEHOLDER: "Buscar",
        LOADING_DATA: "Cargando datos...",
    },
    TABLE_COLUMNS: {
        KEY: "No",
        DATETIME: "Fecha",
        PRODUCT: "Producto",
        MEASSURE: "Medida",
        QTY: "Cant",
        PREVIOUS_STOCK: "Anterior",
        NEW_STOCK: "Nuevo",
        ACTIONS: "Acciones",
    },
    ACTIONS: {
        VIEW_DETAIL: "Ver Detalle",
    },
}

export const regularizationRecordsDictionary = {
    [Languages.ENGLISH_US]: DescriptionsEnUs,
    [Languages.SPANISH_US]: DescriptionsEsUs,
}